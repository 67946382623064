import * as actionTypes from './actionTypes';

export const initUserProfile = (userInfo) => ({
    type: actionTypes.INIT_USER_PROFILE,
    userInfo: userInfo,
});

export const clearInitUserProfile = () => ({
    type: actionTypes.CLEAR_INIT_USER_PROFILE,
});

export const updateUserProfile = (userInfo) => ({
    type: actionTypes.UPDATE_USER_PROFILE,
    userInfo: userInfo
});