const initial_state = {
};

function appReducer(_state, action) {
  let state = _state || initial_state;
  switch (action.type) {
    default:
      return state;
  }
}

export default appReducer;

