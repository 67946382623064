import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { blue } from "@material-ui/core/colors";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { blueGrey } from '@material-ui/core/colors';
import { actionCreators as itineraryActionCreators } from "./store";
import { restore_single_itinerary, update_itinerary, create_trip, restore_user_itinerary, restore_user_profile, restore_trip } from "../../actions/api/content";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  root: {
    justifyContent: "center",
    height: 100,
    padding: 8,
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    },
    drag: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    }
  }
}));

function Itinerary(props) {
  let history = useHistory();
  const classes = useStyles();

  let currentItinId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

  useEffect(() => {
    props.handleRestoreItineraries(props.token);
    props.handleGetUserInfo(props.user_id);
    props.hanldeGetCurrentItin(currentItinId, props.token);
  }, []);

  useEffect(() => {
    if (props.retrieveItinFailure) {
      props.handleRestoreItinFailure();
      history.push("/");
    }
  }, [props.retrieveItinFailure]);

  useEffect(() => {
    console.log("restore trips  " + props.itineraries.length + " " + currentItinId + " " + props.retrieveItinFailure);
    if (props.itineraries.length > 0) {
      props.handleRestoreTrips(currentItinId, props.token);
    }
  }, [props.itineraries.length]);


  useEffect(() => {
    if (props.trips.length > 0) {
      console.log("citylist???" + setUpCityList.length);
      setCityList(setUpCityList);
    }
  }, [props.trips.length]);
  function handleBackToUser() {
    history.push("/profile");
  }

  const setUpCityList = () => {
    let res = [];
    props.trips.map(trip => {
      let currentCityList = '';
      trip.sites.map(site => {
        if (JSON.stringify(site) !== "[]" && !currentCityList.includes(site.site.city.city_name)) {
          currentCityList += site.site.city.city_name + " | ";
        }
      })
      currentCityList = currentCityList.substring(0, currentCityList.length - 3);
      res.push(currentCityList);
    });
    return res;
  }

  function handleAddNewDay() {
    let day = 1;
    if (props.trips.length != 0) {
      day = props.trips[props.trips.length - 1].day + 1;
    }
    const datagram = {
      day: day,
      itinerary: currentItinId,
    }
    props.handleCreateTripDispatch(datagram, props.token);
    props.setCurrentTripIndex(props.trips.length);
  }

  const [citylist, setCityList] = React.useState();

  return (
    <div>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Itinerary Overview
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          ></Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={() => {
                    props.handleSwitchDayTrip()
                    handleAddNewDay()
                    itineraryActionCreators.clearInitCityList();
                    itineraryActionCreators.clearInitTrip();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Add new Day
                </Button>
                {
                  props.singleItinerary === '' ?
                    <div />
                    :
                    props.singleItinerary.is_public === true ?
                      <Button
                        onClick={() => {
                          const datagram = props.singleItinerary;
                          datagram.is_public = false;
                          props.handleSetPublicOrPrivateItin(datagram, datagram.id, props.token)
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Public
                      </Button>
                      :
                      <Button
                        onClick={() => {
                          const datagram = props.singleItinerary;
                          datagram.is_public = true;
                          props.handleSetPublicOrPrivateItin(datagram, datagram.id, props.token)
                        }}
                        variant="contained"
                        color={blueGrey[200]}
                      >
                        Private
                      </Button>
                }
              </Grid>
            </Grid>
          </div>
          <p>&nbsp;</p>
          <Grid container spacing={8} justify="center">
            {
              props.trips.length > 0 ?
                <List className="dayTripList">
                  {
                    props.trips.map((trip, index) => (
                      <ListItem key={trip.id} onClick={() => {
                        props.handleSwitchDayTrip()
                        props.setCurrentTripIndex(index)
                      }}>
                        <Typography component="h2"
                          variant="h6" color="textSecondary">
                          Day {index + 1}:
                      </Typography>
                        {
                          citylist === undefined ?
                            <div />
                            :
                            <ListItemText>
                              {citylist[index]}
                              {/* <Button color="primary">
                              <DragHandleIcon />
                            </Button> */}
                            </ListItemText>
                        }
                      </ListItem>
                    ))
                  }
                </List>
                :
                <div />
            }
          </Grid>
          <Grid container spacing={8} justify="center">
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleBackToUser}
              >
                BACK
            </Button>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleBackToUser}
              >
                Save
            </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div >
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    itineraries: state.itinerary.itineraries,
    trips: state.itinerary.trips,
    user_id: state.auth.user_id,
    retrieveItinFailure: state.itinerary.retrieveItinFailure,
    singleItinerary: state.itinerary.singleItinerary,
    // itinRestoreFin: state.itinerary.itinRestoreFin,
    // tripRestoreFin: state.itinerary.tripRestoreFin,
    // currentItinIndex: state.itinerary.currentItinIndex,
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleCreateTripDispatch(datagram, token) {
      dispatch(create_trip(datagram, token));
    },
    handleRestoreItineraries(token) {
      dispatch(restore_user_itinerary(token));
    },
    handleRestoreTrips(itineraryId, token) {
      dispatch(restore_trip(itineraryId, token));
    },
    handleGetUserInfo(user_id) {
      dispatch(restore_user_profile(user_id));
    },
    handleFinInitItinerary() {
      dispatch(itineraryActionCreators.finInitItinerary())
    },
    handleResetFinInitItinerary() {
      dispatch(itineraryActionCreators.resetFinInitItinerary())
    },
    handleSetCurrentItinIndex(index) {
      dispatch(itineraryActionCreators.setCurrentItineraryIndex(index));
    },
    handleRestoreItinFailure() {
      dispatch(itineraryActionCreators.restoreItinFailure());
    },
    hanldeGetCurrentItin(currentItin, token) {
      dispatch(restore_single_itinerary(currentItin, token));
    },
    handleSetPublicOrPrivateItin(newData, itineraryId, token) {
      dispatch(update_itinerary(newData, itineraryId, token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
