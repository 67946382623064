import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from 'clsx';
import { restore_user, update_dashboard_data, delete_dashboard_data } from "../../actions/api/content";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from '@material-ui/core/Paper';
import NativeSelect from '@material-ui/core/NativeSelect';
import Avatar from '@material-ui/core/Avatar';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from "@material-ui/core/Select";
const useStyles = makeStyles(theme => ({
  // seeMore: {
  //   marginTop: theme.spacing(3)
  // },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  }
}));
function User(props) {
  useEffect(() => {
    props.handleRestoreUser();
  }, []);
  const classes = useStyles();
  const users = props.users;
  const [user_id, setUserId] = React.useState();
  const [email, setEmail] = React.useState();
  const [is_staff, setIsStaff] = React.useState();
  const [is_active, setIsActive] = React.useState();
  const [username, setUsername] = React.useState();
  const [role_change_open, setRoleChangeOpen] = React.useState(false);
  const [deactivate_open, setDeactivateOpen] = React.useState(false);
  const handleDeactivate = () => {
    props.handleDeleteUserDispatch(user_id, props.token);
    setUserId('');
    setIsStaff('');
    setIsActive('');
    setUsername('');
    setEmail('');
  }
  // const handleActivate = () => {
  //   const datagram = {
  //     user_id: user_id,
  //     username: username,
  //     is_staff: is_staff,
  //     is_active: true,
  //     email: email,
  //   }
  //   props.handleUpdateUserDispatch(datagram, user_id, props.token);
  //   setUserId('');
  //   setIsStaff('');
  //   setIsActive('');
  //   setUsername('');
  //   setEmail('');
  // }
  const handleChangeRole = () => {
    const datagram = {
      user_id: user_id,
      username: username,
      is_staff: is_staff,
      is_active: is_active,
      email: email,
    }
    props.handleUpdateUserDispatch(datagram, user_id, props.token);
    setUserId('');
    setIsStaff('');
    setIsActive('');
    setUsername('');
    setEmail('');
  }
  const setRoleState = (user_id, email, is_staff, is_active, username) => {
    setUserId(user_id);
    setIsStaff(is_staff);
    setIsActive(is_active);
    setUsername(username);
    setEmail(email);
  }

  return (
    <Paper className={classes.paper}>
      <React.Fragment>
        <Title>Users</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Last_Login</TableCell>
              <TableCell>Date_Joined</TableCell>
              <TableCell>is_Staff</TableCell>
              <TableCell>is_Active</TableCell>
              <TableCell>User Avatar</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users === undefined ? (
                <div />
              ) : (
                  users.map(user => (
                    <TableRow key={user.user_id}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.last_login}</TableCell>
                      <TableCell>{user.date_joined}</TableCell>
                      <TableCell>{user.is_staff.toString()}</TableCell>
                      <TableCell>{user.is_active.toString()}</TableCell>
                      <TableCell>
                        <Avatar alt="User Avatar" src={user.profile_pic} className={classes.bigAvatar} />
                      </TableCell>
                      <TableCell align="right">
                        <div className="button-wrapper">
                          <button className='button' onClick={() => {
                            setRoleChangeOpen(!role_change_open)
                            setRoleState(user.user_id, user.email, user.is_staff, user.is_active, user.username)
                          }}>
                            <EditIcon />
                          </button>
                          <Dialog
                            disableBackdropClick
                            disableEscapeKeyDown
                            open={role_change_open}
                            onClose={() => setRoleChangeOpen(!role_change_open)}
                          >
                            <DialogTitle>Please select a role</DialogTitle>
                            <DialogContent>
                              <form className={classes.container}>
                                <FormControl className={classes.formControl}>
                                  <InputLabel htmlFor="demo-dialog-native">
                                    Select
                              </InputLabel>
                                  <NativeSelect
                                    onChange={(event) => setIsStaff(event.target.value)}
                                  >
                                    <option value="" />
                                    <option value={true} >&nbsp;&nbsp;&nbsp;Staff&nbsp;&nbsp;&nbsp;</option>
                                    <option value={false}>&nbsp;&nbsp;&nbsp;User&nbsp;&nbsp;&nbsp;</option>
                                  </NativeSelect>
                                </FormControl>
                              </form>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => setRoleChangeOpen(!role_change_open)} color="primary">
                                Cancel
                            </Button>
                              <Button onClick={() => {
                                setRoleChangeOpen(!role_change_open)
                                handleChangeRole()
                              }} color="primary">
                                Ok
                            </Button>
                            </DialogActions>
                          </Dialog>
                          {/* {
                          user.is_active ? */}
                          {/* <div> */}
                          <button className='button'
                            onClick={() => {
                              setDeactivateOpen(!deactivate_open)
                              setUsername(user.username)
                              setRoleState(user.user_id, user.email, user.is_staff, user.is_active, user.username)
                            }}>
                            <DeleteIcon />
                          </button>
                          <Dialog
                            disableBackdropClick
                            disableEscapeKeyDown
                            open={deactivate_open}
                            onClose={() => setDeactivateOpen(!deactivate_open)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Delete City"}</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are you sure to deactivate {username}?
                          </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => {
                                setDeactivateOpen(!deactivate_open)
                                handleDeactivate()
                                setUsername('')
                              }}
                                color="primary">
                                Deactivate
                            </Button>
                              <Button onClick={() => {
                                setDeactivateOpen(!deactivate_open)
                                setUsername('')
                              }} color="primary" autoFocus>
                                Cancel
                            </Button>
                            </DialogActions>
                          </Dialog>
                          {/* </div>
                            :
                            <div>
                              <Button variant="contained" color="primary" className={classes.button}
                                onClick={() => {
                                  setDeactivateOpen(!deactivate_open)
                                  setUsername(user.username)
                                  setRoleState(user.user_id, user.email, user.is_staff, user.is_active, user.username)
                                }}>
                                Activate
                        </Button>
                              <Dialog
                                open={deactivate_open}
                                onClose={() => setDeactivateOpen(!deactivate_open)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              > }
                                <DialogTitle id="alert-dialog-title">{"Delete City"}</DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    Are you sure to Activate {username}?
                          </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => {
                                    setDeactivateOpen(!deactivate_open)
                                    handleActivate()
                                    setUsername('')
                                  }}
                                    color="primary">
                                    Activate
                            </Button>
                                  <Button onClick={() => {
                                    setDeactivateOpen(!deactivate_open)
                                    setUsername('')
                                  }} color="primary" autoFocus>
                                    Cancel
                            </Button>
                                </DialogActions>
                              </Dialog>
                            </div> */
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
          </TableBody>
        </Table>
      </React.Fragment>
    </Paper >
  );
}
//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    users: state.admin.users,
    token: state.auth.access_token
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreUser() {
      dispatch(restore_user());
    },
    handleUpdateUserDispatch(datagram, id, token) {
      dispatch(update_dashboard_data(datagram, '', id, "user", token));
    },
    handleDeleteUserDispatch(id, token) {
      dispatch(delete_dashboard_data(id, "user", token));
    }
    // handleDeleteUserDispatch(datagram, id, token) {
    //   dispatch(dle_dashboard_data(datagram, '', id, "user", token));
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
