import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { actionCreators as itineraryActionCreators } from "./store";
import { withStyles } from "@material-ui/core/styles";
import {
  restore_user_itinerary,
  restore_trip,
  update_trip_site,
  delete_trip_site,
  delete_trip,
  restore_single_trip
} from "../../actions/api/content";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  root: {
    width: "100%"
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex",
    margin: "5px 0"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

function DayTrip(props) {
  const classes = useStyles();
  let history = useHistory();
  const [tempCityListStr, setTempCityListStr] = React.useState();
  const [tempCityList, setTempCityList] = React.useState([]);

  useEffect(() => {
    if (props.singleTrip !== undefined && props.singleTrip !== "") {
      setUpTempCityList();
      setSitelist(props.singleTrip);
    }
  }, [props.singleTrip]);

  // useEffect(() => {
  //   if (props.singleTrip !== undefined && props.singleTrip !== "") {
  //     props.handleRestoreSingleTrip(props.trips[props.currentTripIndex].id, props.token);
  //   }
  // }, [props.singleTrip]);

  useEffect(() => {
    if (props.trips[props.currentTripIndex] !== undefined) {
      props.handleRestoreSingleTrip(
        props.trips[props.currentTripIndex].id,
        props.token
      );
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState();
  const [selectedSite, setSelectedSite] = React.useState();
  const [moveOrderValidation, setMoveOrderValidation] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changeSiteOrder() {
    const datagram = {
      site: selectedSite.id,
      order: selectedOrder,
      day_trip: props.singleTrip.id
    };
    props.handleChangeSiteOrder(
      datagram,
      selectedSite.id,
      props.token
    );
  }

  function setUpTempCityList() {

    let res = "";
    let resArr = [];
    if (props.singleTrip !== undefined && props.singleTrip.length > 0) {
      console.log("got here" + JSON.stringify(props.singleTrip));
      props.singleTrip.map(site => {
        if (!res.includes(site.site.city.city_name)) {
          res += site.site.city.city_name + " | ";
          resArr.push(site.site.city);
        }
      });
    }
    if (props.tempCitylist !== undefined) {
      console.log("got here" + JSON.stringify(props.tempCityList));
      for (let i = 0; i < props.tempCitylist.length; i++) {
        if (!res.includes(props.tempCitylist[i].city_name)) {
          res += props.tempCitylist[i].city_name + " | ";
          resArr.push(props.tempCitylist[i]);
        }
      }
    }
    res = res.substring(0, res.length - 3);
    setTempCityList(resArr);
    setTempCityListStr(res);
  }

  function moveOrderValidate(order) {
    setMoveOrderValidation(order > sitelist.length || order <= 0);
  }

  function deleteTripSite() {
    props.handleDeleteTripSite(
      selectedSite.id,
      selectedSite.order - 1,
      props.token
    );
  }

  function deleteDayTrip() {
    props.handleDeleteTrip(
      props.trips[props.currentTripIndex].id,
      props.currentTripIndex,
      props.token
    );
  }

  const [sitelist, setSitelist] = React.useState({}); // sites in database

  return (
    <div>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Day {props.currentTripIndex + 1}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            {tempCityListStr}
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.handleSwitchAddCity();
                  }}
                >
                  Add City
                </Button>
                <Button
                  onClick={event => setAnchorEl(event.currentTarget)}
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                >
                  Add Site
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.handleSwitchItinerary();
                    deleteDayTrip();
                  }}
                >
                  Delete Day
                </Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {tempCityList.map((city, index) => (
                    <StyledMenuItem key={index}>
                      {city === undefined ? (
                        <div />
                      ) : (
                          <ListItemText
                            primary={city.city_name}
                            onClick={() => {
                              props.handleSwitchAddSite();
                              props.handleClearSiteFilterResult();
                              props.setCityIdFilter(city.id);
                            }}
                          ></ListItemText>
                        )}
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={10} justify="center">
          {sitelist !== undefined && JSON.stringify(sitelist) !== "{}" ? (
            sitelist.map(site => (
              <CardActionArea>
                <Card className={classes.card}>
                  <Hidden
                    xsDown
                    onClick={() => {
                      history.push(
                        `/site/detail/${site.site.site_category}_${site.site.id}`
                      );
                    }}
                  >
                    <CardMedia
                      className={classes.cardMedia}
                      image={site.site.photo}
                      title="Image title"
                    />
                  </Hidden>
                  <div
                    className={classes.cardDetails}
                    onClick={() => {
                      history.push(
                        `/site/detail/${site.site.site_category}_${site.site.id}`
                      );
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6">
                        {site.site.city !== null &&
                          site.site.city !== undefined ? (
                            <span>
                              {site.site.name}-{site.site.city.city_name}
                            </span>
                          ) : (
                            <div />
                          )}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {site.site.description.slice(0, 160) + "..."}
                      </Typography>
                    </CardContent>
                  </div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setDeleteOpen(true);
                      setSelectedSite(site);
                    }}
                  >
                    <DeleteForeverIcon />
                  </Button>
                  <Dialog
                    onClose={() => {
                      setSelectedSite();
                      setDeleteOpen(false);
                    }}
                    aria-labelledby="simple-dialog-title"
                    open={deleteOpen}
                  >
                    <DialogTitle id="simple-dialog-title">
                      Are you sure to delete this site?
                    </DialogTitle>
                    <Button
                      onClick={() => {
                        deleteTripSite();
                        setSelectedSite();
                        setDeleteOpen(false);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        setDeleteOpen(false);
                        setSelectedSite();
                      }}
                    >
                      Cancel
                    </Button>
                  </Dialog>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEditOpen(true);
                      setSelectedSite(site);
                    }}
                  >
                    <DragHandleIcon />
                  </Button>
                  <Dialog
                    onClose={() => {
                      setSelectedOrder();
                      setEditOpen(false);
                    }}
                    aria-labelledby="simple-dialog-title"
                    open={editOpen}
                  >
                    <div className="margin-small">
                      <DialogTitle id="simple-dialog-title">
                        Change site order to
                        <Typography variant="subtitle1" color="textSecondary">
                          Range From 1 - {sitelist.length}
                        </Typography>
                      </DialogTitle>
                      <TextField
                        id="outlined-helperText"
                        label="Order"
                        type="number"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        error={moveOrderValidation}
                        onChange={e => {
                          setSelectedOrder(e.target.value);
                          moveOrderValidate(e.target.value);
                        }}
                      />
                      <br />
                      <Typography variant="subtitle1" color="textSecondary">
                        <Button
                          onClick={() => {
                            changeSiteOrder();
                            setEditOpen(false);
                            setSelectedOrder();
                          }}
                        >
                          Confirm
                        </Button>
                        <Button
                          onClick={() => {
                            setEditOpen(false);
                            setSelectedOrder();
                          }}
                        >
                          Cancel
                        </Button>
                      </Typography>
                    </div>
                  </Dialog>
                </Card>
              </CardActionArea>
            ))
          ) : (
              <div />
            )}
        </Grid>
      </Container>
      <div className={classes.heroButtons}>
        <Grid container spacing={8} justify="center">
          <Grid item>
            <Button
              onClick={() => {
                props.handleSwitchItinerary()
                props.handleClearSingleTrip();
                setTempCityList('');
                setTempCityListStr('');
                itineraryActionCreators.clearTempCitylist();
              }}
              size="large"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                props.handleSwitchItinerary()
                props.handleClearSingleTrip();
                setTempCityList('');
                setTempCityListStr('');
                itineraryActionCreators.clearTempCitylist();
              }}
              size="large"
              variant="outlined"
              color="primary"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    trips: state.itinerary.trips,
    singleTrip: state.itinerary.singleTrip,
    itineraries: state.itinerary.itineraries,
    user_id: state.auth.user_id,
    itinRestoreFin: state.itinerary.itinRestoreFin,
    tripRestoreFin: state.itinerary.tripRestoreFin,
    tempCitylist: state.itinerary.tempCitylist
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreItineraries(token) {
      dispatch(restore_user_itinerary(token));
    },
    handleRestoreTrips(itineraryId, token) {
      dispatch(restore_trip(itineraryId, token));
    },
    handleChangeSiteOrder(newData, daytripsiteId, token) {
      dispatch(update_trip_site(newData, daytripsiteId, token));
    },
    handleDeleteTripSite(tripSiteId, tripSiteIndex, token) {
      dispatch(delete_trip_site(tripSiteId, tripSiteIndex, token));
    },
    handleDeleteTrip(tripId, tripIndex, token) {
      dispatch(delete_trip(tripId, tripIndex, token));
    },
    handleClearSiteFilterResult() {
      dispatch(itineraryActionCreators.clearInitSite());
    },
    handleRestoreSingleTrip(tripId, token) {
      dispatch(restore_single_trip(tripId, token));
    },
    handleClearSingleTrip() {
      dispatch(itineraryActionCreators.clearInitSingleTrip());
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DayTrip);
