import * as actionTypes from "./actionTypes";

/**
 * General
 */
export const updateDashboardData = (datagram, id, dataType) => ({
  type: actionTypes.UPDATE_DASHBOARD_DATA,
  datagram: datagram,
  id: id,
  dataType: dataType
});

export const createDashboardData = (datagram, dataType) => ({
  type: actionTypes.CREATE_DASHBOARD_DATA,
  datagram: datagram,
  dataType: dataType
});

export const deleteDashboardData = (id, dataType) => ({
  type: actionTypes.DELETE_DASHBOARD_DATA,
  id: id,
  dataType: dataType
});

/**
 * Cities
 */
export const initCities = cityDatagram => ({
  type: actionTypes.INIT_CITIES,
  cityDatagram: cityDatagram
});

export const clearInitCities = () => ({
  type: actionTypes.CLEAR_INIT_CITIES
});

/**
 * Featured
 */
export const initFeatured = featuredDatagram => ({
  type: actionTypes.INIT_FEATURED,
  featuredDatagram: featuredDatagram
});

export const clearInitFeatured = () => ({
  type: actionTypes.CLEAR_INIT_FEATURED
});

/**
 * Highlights
 */
export const initHighlights = highlightDatagram => ({
  type: actionTypes.INIT_HIGHLIGHTS,
  highlightDatagram: highlightDatagram
});

export const clearInitHighlights = () => ({
  type: actionTypes.CLEAR_INIT_HIGHLIGHTS
});

/**
 * User
 */
export const initUsers = userDatagram => ({
  type: actionTypes.INIT_USERS,
  userDatagram: userDatagram
});

export const clearInitUsers = () => ({
  type: actionTypes.CLEAR_INIT_USERS
});

/**
 * Sites
 */
export const updateImageData = (datagram, id, dataType) => ({
  type: actionTypes.UPDATE_IMAGE_DATA,
  datagram: datagram,
  id: id,
  dataType: dataType
});

/**
 * Attractions
 */
export const initAttractions = attractionDatagram => ({
  type: actionTypes.INIT_ATTRACTIONS,
  attractionDatagram: attractionDatagram
});

export const clearInitAttractions = () => ({
  type: actionTypes.CLEAR_INIT_ATTRACTIONS
});

/**
 * Restaurants
 */
export const initRestaurants = restaurantDatagram => ({
  type: actionTypes.INIT_RESTAURANTS,
  restaurantDatagram: restaurantDatagram
});

export const clearInitRestaurants = () => ({
  type: actionTypes.CLEAR_INIT_RESTAURANTS
});

/**
 * Hotels
 */
export const initHotels = hotelDatagram => ({
  type: actionTypes.INIT_HOTELS,
  hotelDatagram: hotelDatagram
});

export const clearInitHotels = () => ({
  type: actionTypes.CLEAR_INIT_HOTELS
});

// export const initGroups = groupDatagram => ({
//   type: actionTypes.INIT_GROUPS,
//   groupDatagram: groupDatagram
// });

/**
 * Like
 */

export const unlikeItineraryHome = (index) => ({
  type: actionTypes.UNLIKE_ITINERARY_HOME,
  index: index
})

export const likeItineraryHome = (index) => ({
  type: actionTypes.LIKE_ITINERARY_HOME,
  index: index
})

export const unlikeItineraryHomeById = (id) => ({
  type: actionTypes.UNLIKE_ITINERARY_HOME_BY_ID,
  id: id
})

export const likeItineraryHomeById = (id) => ({
  type: actionTypes.LIKE_ITINERARY_HOME_BY_ID,
  id: id
})