import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  restore_highlight,
  update_dashboard_data,
  create_dashboard_data,
  delete_dashboard_data
} from "../../actions/api/content";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import Image from "material-ui-image";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles(theme => ({
  // seeMore: {
  //   marginTop: theme.spacing(3)
  // },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

function Highlight(props) {
  useEffect(() => {
    props.handleRestoreHighlight();
  }, []);

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const highlights = props.highlights;

  const [tempFile, setTempFile] = React.useState();
  const [id, setId] = React.useState();
  const [headertext, setHeaderText] = React.useState();
  const [url, setURL] = React.useState();

  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleSubmitEdit = () => {
    const datagram = {
      id: id,
      headertext: headertext,
      url: url
    };
    console.log(datagram);
    props.handleUpdateHighlightDispatch(datagram, tempFile, id, props.token);
    setTempFile("");
    setEditOpen(!editOpen);
    setId("");
    setHeaderText("");
    setURL("");
  };

  const handleCreateHighlight = () => {
    const datagram = {
      id: id,
      headertext: headertext,
      url: url
    };
    props.handleCreateHighlightDispatch(datagram, tempFile, props.token);
    setTempFile("");
    setCreateOpen(!createOpen);
    setId("");
    setHeaderText("");
    setURL("");
  };
  const onFileChange = event => {
    return setTempFile(event.target.files[0]);
  };

  return (
    <Paper className={fixedHeightPaper}>
      <React.Fragment>
        <Title>
          Highlight
          <Button color="primary" onClick={() => setCreateOpen(!createOpen)}>
            <AddIcon />
          </Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={createOpen}
            onClose={() => setCreateOpen(!createOpen)}
          >
            <DialogTitle>Creating Highlight</DialogTitle>
            <DialogContent>
              {/* <TextField
                id="outlined-helperText"
                label="Itinerary ID"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              /> */}
              <TextField
                id="outlined-helperText"
                label="Header Text"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="outlined-helperText"
                label="URL"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span">
                    Upload Photo
                    <PublishIcon />
                  </Button>
                </label>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setCreateOpen(!createOpen)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={() => handleSubmitEdit()} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Title>
        <Table class="dashboard-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>HeaderText</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {highlights === undefined ? (
              <div>nothing!</div>
            ) : (
              highlights.map(highlight => (
                <TableRow key={highlight.id}>
                  <TableCell>{highlight.id}</TableCell>
                  <TableCell>{highlight.headertext}</TableCell>
                  <TableCell>{highlight.url}</TableCell>
                  <TableCell>
                    <Image
                      id="highlight-image"
                      src={highlight.photo}
                      aspectRatio={16 / 9}
                    />
                  </TableCell>

                  <TableCell align="right">
                    <Button
                      color="primary"
                      onClick={() => {
                        setEditOpen(!editOpen);
                        setId(highlight.id);
                        setHeaderText(highlight.headertext);
                        setURL(highlight.url);
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      open={editOpen}
                      onClose={() => setEditOpen(!editOpen)}
                    >
                      <DialogTitle>Editing Highlight {id}</DialogTitle>
                      <DialogContent>
                        {/* <TextField
                            id="outlined-helperText"
                            label="Itinerary ID"
                            defaultValue={id}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          /> */}
                        <TextField
                          id="outlined-helperText"
                          label="Header Text"
                          defaultValue={headertext}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setHeaderText(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="URL"
                          defaultValue={url}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setURL(e.target.value)}
                        />
                        <div>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={onFileChange}
                          />
                          <label htmlFor="raised-button-file">
                            <Button component="span">
                              Change Photo
                              <PublishIcon />
                            </Button>
                          </label>
                          {tempFile === undefined || tempFile === "" ? (
                            <p>No file change</p>
                          ) : (
                            <p>Photo Uploaded </p>
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setEditOpen(!editOpen)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleSubmitEdit()}
                          color="primary"
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button
                      color="primary"
                      onClick={() => {
                        setDeleteOpen(true);
                        setId(highlight.id);
                        setHeaderText(highlight.headertext);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Dialog
                      open={deleteOpen}
                      onClose={() => setDeleteOpen(!deleteOpen)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to delete highlight {highlight.id}?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            props.handleDeleteHighlightDispatch(
                              id,
                              props.token
                            );
                            setId("");
                            setHeaderText("");
                          }}
                          color="primary"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => setDeleteOpen(!deleteOpen)}
                          color="primary"
                          autoFocus
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* <div className={classes.seeMore}>
                <Link color="primary" href="javascript:;">
                    See more cities
                    </Link>
            </div> */}
      </React.Fragment>
    </Paper>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    highlights: state.admin.highlights,
    token: state.auth.access_token
  };
};

// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreHighlight() {
      dispatch(restore_highlight());
    },
    handleCreateHighlightDispatch(datagram, tempFile, token) {
      dispatch(create_dashboard_data(datagram, tempFile, "highlight", token));
    },
    handleUpdateHighlightDispatch(datagram, tempFile, id, token) {
      dispatch(
        update_dashboard_data(datagram, tempFile, id, "highlight", token)
      );
    },
    handleDeleteHighlightDispatch(id, token) {
      dispatch(delete_dashboard_data(id, "highlight", token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Highlight);
