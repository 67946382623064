import React from "react";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DayTrip from "./DayTrip";
import AddCity from "./AddCity";
import AddSite from "./AddSites";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Itinerary from "./Itinerary";
import { create_trip } from "../../actions/api/content";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
}));

function ItineraryContainer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    itinerary: true,
    dayTrip: false,
    addCities: false,
    addSites: false,
  });

  const [cityIdFilter, setCityIdFilter] = React.useState();
  const [currentTripIndex, setCurrentTripIndex] = React.useState(0); // trip index

  const handleSwitchItinerary = () => {
    setState({
      itinerary: true,
      dayTrip: false,
      addCities: false,
      addSites: false,
    });
  };

  const handleSwitchDayTrip = () => {
    setState({
      itinerary: false,
      dayTrip: true,
      addCities: false,
      addSites: false,
    });
  };
  const handleSwitchAddSite = () => {
    setState({
      itinerary: false,
      dayTrip: false,
      addCities: false,
      addSites: true,
    });
  };
  const handleSwitchAddCity = () => {
    setState({
      itinerary: false,
      dayTrip: false,
      addCities: true,
      addSites: false,
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <Avatar
            alt="User Avatar"
            className={classes.bigAvatar}
            src={props.profile_pic}
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Typography variant="h6" color="inherit" noWrap>
            Hi, {props.username}
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {
          state.itinerary ? (
            <Itinerary
              handleSwitchDayTrip={handleSwitchDayTrip}
              setCurrentTripIndex={setCurrentTripIndex}
              currentTripIndex={currentTripIndex}
            />
          ) : (
              <div />
            )
        }
        {
          state.dayTrip ? (
            <DayTrip
              handleSwitchAddCity={handleSwitchAddCity}
              handleSwitchAddSite={handleSwitchAddSite}
              handleSwitchItinerary={handleSwitchItinerary}
              setCityIdFilter={setCityIdFilter}
              setCurrentTripIndex={setCurrentTripIndex}
              currentTripIndex={currentTripIndex}
            />
          ) : (
              <div />
            )}
        {
          state.addCities ? (
            <AddCity
              handleSwitchDayTrip={handleSwitchDayTrip}
              currentTripIndex={currentTripIndex}
            />
          ) : (
              <div />
            )
        }
        {
          state.addSites ? (
            <AddSite
              handleSwitchAddSite={handleSwitchAddSite}
              handleSwitchDayTrip={handleSwitchDayTrip}
              cityIdFilter={cityIdFilter}
              currentTripIndex={currentTripIndex}
              setCityIdFilter={setCityIdFilter}
            />
          ) : (
              <div />
            )
        }
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Find us @
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <TwitterIcon />
          <FacebookIcon />
        </Typography>
        <Copyright />
      </footer>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    logged_in: state.auth.access_token !== undefined,
    is_staff: state.auth.is_staff === "true",
    username: state.auth.username,
    profile_pic: state.user.profile_pic,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleCreateDayTrip(user_id) {
      dispatch(create_trip(user_id));
    }
  };
};

export default connect(mapStateToProps)(ItineraryContainer);
