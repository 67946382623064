import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
// import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    // seeMore: {
    //     marginTop: theme.spacing(3)
    // },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function DashboardHome(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <Paper className={fixedHeightPaper}>
            <p>Home</p>
        </Paper>
    );
}
// react-redux main API 1: map store.state to props
// const mapStateToProps = (state) => {
//     return {
//     }
// }

// // react-redux main API 2: map store.dispatch to props
// const mapDispatchToProps = (dispatch) => { }
// export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);