import axios from "axios";
import { login } from "./auth";
const api_request = axios.create({
    baseURL: `http://www.mytriphub.net/api/`
});

const register_request = () => ({ "type": "REGISTER_REQUEST" });
const register_success = (data) => ({
    "type": "REGISTER_SUCCESS",
    "payload": data,
});
const register_failure = () => ({ "type": "REGISTER_FAILURE" });


const login_success = (refresh_token, access_token, username, is_staff, user_id) => ({
    "type": "LOGIN_SUCCESS",
    "payload": {
        "refresh_token": refresh_token,
        "access_token": access_token,
        "username": username,
        "is_staff": is_staff,
        "user_id": user_id,
    },
});


export function register(username, firstname, lastname, email, password) {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('first_name', firstname);
        formData.append('email', email);
        formData.append('last_name', lastname);
        formData.append('password', password);
        return api_request.post('user/', formData)
            .then((response) => {
                console.log("register success" + response.data);
                dispatch(register_success(response.data));
                dispatch(login(username, password));
            })
            .catch((error) => {
                dispatch(register_failure());
                console.log("register failure" + JSON.stringify(error.response));
                alert(JSON.stringify(error.response.data));
            });
    };
}