import axios from 'axios';

const api_request = axios.create({
  baseURL: 'http://www.mytriphub.net/api/'
});

const login_success = (refresh_token, access_token, username, is_staff, user_id) => ({
  "type": "LOGIN_SUCCESS",
  "payload": {
    "refresh_token": refresh_token,
    "access_token": access_token,
    "username": username,
    "is_staff": is_staff,
    "user_id": user_id,
  },
});

const refresh_token_success = (refresh_token, access_token, username, is_staff, user_id) => ({
  "type": "UPDATE_TOKEN",
  "payload": {
    "refresh_token": refresh_token,
    "access_token": access_token,
    "username": username,
    "is_staff": is_staff,
    "user_id": user_id,
  },
});

const logout_success = () => ({
  "type": "LOGOUT_SUCCESS",
  "payload": {
    "refresh_token": '',
    "access_token": '',
    "username": '',
    "is_staff": '',
    "user_id": '',
  },
});
/**
 * User login function
 * @param {will be changed to email to login later} username 
 * @param {user input password for login} password 
 */
export function login(username, password) {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return api_request.post('token-auth/', formData)
      .then((response) => {
        const data = response.data;
        console.log(data);
        const staff = data.is_staff ? "true" : "false";
        const user_id = data.user_id;
        console.log("login" + user_id);
        dispatch(login_success(data.refresh, data.access, username, staff, user_id));
        localStorage.setItem("username", username);
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        localStorage.setItem("is_staff", staff);
        localStorage.setItem("user_id", user_id);
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  }
}

export function refresh_token(token) {
  console.log("refreshing token");
  return async (dispatch) => {
    console.log("refresh token" + token);
    return api_request.post("token-auth/refresh/", { "refresh": token })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access);
        console.log("refresh token response" + response.data);
        const username = localStorage.getItem("username");
        const is_staff = localStorage.getItem("is_staff");
        const user_id = localStorage.getItem("user_id");
        console.log("refresh " + user_id);
        dispatch(refresh_token_success(token, response.data.access, username, is_staff, user_id));
      })
      .catch((error) => {
        logout();
        return Promise.reject(error);
      });
  };
}

export function logout() {
  return async (dispatch) => {
    console.log("user logged out");
    dispatch(logout_success());
    localStorage.setItem("access_token", '');
    localStorage.setItem("refresh_token", '');
    localStorage.setItem("is_staff", '');
    localStorage.setItem("user_id", '');
  };
}