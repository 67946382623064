const initial_state = {
  "_change": 0, // triggers update in AppManager
}

const defaultState = {
  logged_in: false,
  username: '',
  user_id: '',
}

function authReducer(_state, action) {
  let state = _state || initial_state;
  switch (action.type) {
    /*
     * Login
     */
    case "LOGIN_REQUEST":
      return Object.assign({}, state, {
        "logging_in": true,
      });
    case "LOGIN_SUCCESS":
      return Object.assign({}, state, {
        "refresh_token": action.payload.refresh_token,
        "access_token": action.payload.access_token,
        "username": action.payload.username,
        "logging_in": false,
        "is_staff": action.payload.is_staff,
        "user_id": action.payload.user_id,
      });
    case "LOGIN_FAILURE":
      return Object.assign({}, state, {
        "logging_in": false,
      });

    /*
     * Register
     */
    case "REGISTER_REQUEST":
      return Object.assign({}, state, {
        "registering": true,
      });
    case "REGISTER_SUCCESS":
      return Object.assign({}, state, {
        "token": action.payload.token,
        "username": action.payload.username,
        "registering": false,
      });
    case "REGISTER_FAILURE":
      return Object.assign({}, state, {
        "registering": false,
      });

    /*
     * Logout
     */
    case "LOGOUT_SUCCESS":
      return Object.assign({}, initial_state, {
        "_change": state._change + 1,
        "refresh_token": undefined,
        "access_token": undefined,
        "username": undefined,
        "user_id": undefined
      });

    /*
     * Token Management
     */
    case "UPDATE_TOKEN":
      return Object.assign({}, state, {
        "refresh_token": action.payload.refresh_token,
        "access_token": action.payload.access_token,
        "username": action.payload.username,
        "is_staff": action.payload.is_staff,
        "user_id": action.payload.user_id,
      });

    default:
      return state;
  }
}

export default authReducer;