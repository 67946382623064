import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { Collapse } from "@material-ui/core";
import Attraction from "./Attraction";
import Hotel from "./Hotel";
import Restaurant from "./Restaurant";
import DashboardHome from "./DashboardHome";
import Highlight from "./Highlight";
import FeaturedTrip from "./FeaturedTrip";
import User from "./User";
import City from "./City";
import { connect } from "react-redux";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://localhost:3000">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    zIndex: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

const collapseStyles = makeStyles(theme => ({
  root: {
    display: "in-line"
  }
}));

function Dashboard(props) {
  const classes = useStyles();
  const collapseClasses = collapseStyles();
  const [open, setOpen] = React.useState(true);
  const [collapse, setCollapse] = React.useState(false);
  const [state, setState] = React.useState({
    dashboard: true,
    featuredTrip: false,
    highlightItinerary: false,
    user: false,
    city: false,
    hotel: false,
    restaurant: false,
    attraction: false
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSwitchDashboard = () => {
    setState({
      dashboard: true,
      featuredTrip: false,
      highlightItinerary: false,
      user: false,
      city: false,
      hotel: false,
      restaurant: false,
      attraction: false
    });
  };

  const handleSwitchFeaturedTrip = () => {
    setState({
      dashboard: false,
      featuredTrip: true,
      highlightItinerary: false,
      user: false,
      city: false,
      hotel: false,
      restaurant: false,
      attraction: false
    });
  };

  const handleSwitchHighlightItinerary = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: true,
      user: false,
      site: false,
      city: false,
      hotel: false,
      restaurant: false,
      attraction: false
    });
  };

  const handleSwitchRole = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: false,
      user: true,
      city: false,
      hotel: false,
      restaurant: false,
      attraction: false
    });
  };

  const handleSwitchCity = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: false,
      user: false,
      city: true,
      hotel: false,
      restaurant: false,
      attraction: false
    });
  };
  const handleSwitchHotel = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: false,
      user: false,
      city: false,
      hotel: true,
      restaurant: false,
      attraction: false
    });
  };
  const handleSwitchRestaurant = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: false,
      user: false,
      city: false,
      hotel: false,
      restaurant: true,
      attraction: false
    });
  };
  const handleSwitchAttraction = () => {
    setState({
      dashboard: false,
      featuredTrip: false,
      highlightItinerary: false,
      user: false,
      city: false,
      hotel: false,
      restaurant: false,
      attraction: true
    });
  };

  const handleSiteCollapseMenu = () => {
    setCollapse(!collapse);
  };

  let history = useHistory();
  const handleRedirectHome = () => {
    console.log("triggered home");
    history.push("/home");
  };

  return (
    <div>
      {props.is_staff && props.logged_in ? (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                Dashboard
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              )
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListSubheader inset>Home Setup</ListSubheader>
              <ListItem button onClick={handleSwitchDashboard}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem button onClick={handleSwitchFeaturedTrip}>
                <ListItemIcon>
                  <DriveEtaIcon />
                </ListItemIcon>
                <ListItemText primary="Featured Itinerary" />
              </ListItem>
              {/* </NavLink>
                        <NavLink to="/admin/highlight"> */}
              <ListItem button onClick={handleSwitchHighlightItinerary}>
                <ListItemIcon>
                  <WbIncandescentIcon />
                </ListItemIcon>
                <ListItemText primary="Highlight" />
              </ListItem>
              {/* </NavLink> */}
            </List>
            <Divider />
            <List>
              <ListSubheader inset>Management</ListSubheader>
              {/* <NavLink to="/admin/role"> */}
              <ListItem button onClick={handleSwitchRole}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItem>
              {/* </NavLink> */}
              <ListItem button onClick={handleSiteCollapseMenu}>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Site" />
                <ExpandMoreIcon />
              </ListItem>
              <Collapse in={collapse} className={collapseClasses.root}>
                {/* <NavLink to="/admin/attraction"> */}
                <ListItem button onClick={handleSwitchAttraction}>
                  <Grid item xs={2}></Grid>
                  <ListItemIcon>
                    <FilterHdrIcon />
                  </ListItemIcon>
                  <ListItemText primary="Attraction" />
                </ListItem>
                {/* </NavLink>
                        <NavLink to="/admin/restaurant"> */}
                <ListItem button onClick={handleSwitchRestaurant}>
                  <Grid item xs={2}></Grid>
                  <ListItemIcon>
                    <RestaurantIcon />
                  </ListItemIcon>
                  <ListItemText primary="Restaurant" />
                </ListItem>
                {/* </NavLink>
                        <NavLink to="/admin/hotel"> */}
                <ListItem button onClick={handleSwitchHotel}>
                  <Grid item xs={2}></Grid>
                  <ListItemIcon>
                    <HotelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Hotel" />
                </ListItem>
                {/* </NavLink> */}
              </Collapse>
              {/* <NavLink to="/admin/city"> */}
              <ListItem button onClick={handleSwitchCity}>
                <ListItemIcon>
                  <LocationCityIcon />
                </ListItemIcon>
                <ListItemText primary="City" />
              </ListItem>
              {/* </NavLink> */}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {state.dashboard ? <DashboardHome /> : <div />}
                  {state.featuredTrip ? <FeaturedTrip /> : <div />}
                  {state.highlightItinerary ? <Highlight /> : <div />}
                  {state.city ? <City /> : <div />}
                  {state.attraction ? <Attraction /> : <div />}
                  {state.restaurant ? <Restaurant /> : <div />}
                  {state.hotel ? <Hotel /> : <div />}
                  {state.user ? <User /> : <div />}
                </Grid>
              </Grid>
            </Container>
            <Copyright />
          </main>
        </div>
      ) : (
        handleRedirectHome()
      )}
    </div>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    logged_in: state.auth.access_token !== undefined,
    is_staff: state.auth.is_staff === "true"
  };
};

export default connect(mapStateToProps)(Dashboard);
