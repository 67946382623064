import * as actionTypes from "./actionTypes";

const defaultState = {
  profile_pic: '',
  first_name: '',
  last_name: '',
  email: '',
}

export default (state = defaultState, action) => {
  if (action.type === actionTypes.INIT_USER_PROFILE) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.profile_pic = action.userInfo.profile_pic;
    newState.first_name = action.userInfo.first_name;
    newState.last_name = action.userInfo.last_name;
    newState.email = action.userInfo.email;
    return newState;
  }

  if (action.type === actionTypes.CLEAR_INIT_USER_PROFILE) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.profile_pic = '';
    newState.first_name = '';
    newState.last_name = '';
    newState.email = '';
    return newState;
  }

  if (action.type === actionTypes.UPDATE_USER_PROFILE) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.profile_pic = action.userInfo.profile_pic;
    newState.first_name = action.userInfo.first_name;
    newState.last_name = action.userInfo.last_name;
    newState.email = action.userInfo.email;
    return newState;
  }

  return state;
} 
