/**
 * for staff
 */
export const UPDATE_DASHBOARD_DATA = "updateDashboardData";
export const CREATE_DASHBOARD_DATA = "createDashboardData";
export const DELETE_DASHBOARD_DATA = "deleteDashboardData";

export const UPDATE_IMAGE_DATA = "updateImageData";

export const INIT_CITIES = "initCities";
export const CLEAR_INIT_CITIES = "clearInitCities";

export const INIT_FEATURED = "initFeatured";
export const CLEAR_INIT_FEATURED = "clearinitFeatured";

export const INIT_HIGHLIGHTS = "initHighlights";
export const CLEAR_INIT_HIGHLIGHTS = "clearinitHighlights";

export const INIT_USERS = "initUsers";
export const CLEAR_INIT_USERS = "clearinitUsers";

export const INIT_ATTRACTIONS = "initAttractions";
export const CLEAR_INIT_ATTRACTIONS = "clearInitAttractions";

export const INIT_RESTAURANTS = "initRestaurants";
export const CLEAR_INIT_RESTAURANTS = "clearInitRestaurants";

export const INIT_HOTELS = "initHotels";
export const CLEAR_INIT_HOTELS = "clearInitHotels";

// export const INIT_GROUPS = "initGroups";
// export const CLEAR_INIT_GROUPS = "clearinitGroups";

export const LIKE_ITINERARY_HOME = 'likeItineraryHome';
export const UNLIKE_ITINERARY_HOME = 'unlikeItineraryHome';
export const UNLIKE_ITINERARY_HOME_BY_ID = 'unlikeItineraryHomeById';
export const LIKE_ITINERARY_HOME_BY_ID = 'likeItineraryHomeById';
