import React from "react";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import { pink, indigo, blueGrey } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { restore_trip, restore_single_itinerary, update_itinerary, like_itinerary, unlike_itinerary } from "../../actions/api/content";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import hotel from '../../static/hotel.png';
import attraction from '../../static/attraction.png';
import store from '../../static/store.png';
import CssBaseline from "@material-ui/core/CssBaseline";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityIcon from '@material-ui/icons/Visibility';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    minHeight: "300px"
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  favorite: {
    color: pink[400],
    margin: "0 10px",
  },
  view: {
    color: indigo[400],
    margin: "0 10px",
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  visitor: {
    minHeight: "60px",
    padding: "15px"
  },
  info: {
    width: "100%",
  },
  left: {
    float: "left",
    width: "80%",
    padding: "20px"
  },
  right: {
    float: "right",
    width: "100px",
  }
}));

function ItineraryDetailView(props) {
  const classes = useStyles();
  const history = useHistory();
  let currentItinId = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  useEffect(() => {
    props.handleRestoreTrips(currentItinId, props.token);
    props.hanldeGetCurrentItin(currentItinId, props.token);
  }, []);

  function handleLoggedOut() {
    console.log("triggered home");
    history.push("/home");
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        {
          props.username === undefined || props.username === "" ?
            <Typography className={classes.visitor} variant="h6" color="inherit" noWrap>
              Hi, visitor
            </Typography>
            :
            <Toolbar className={classes.toolbar}>
              <Avatar
                alt="User Avatar"
                className={classes.bigAvatar}
                src={props.profile_pic}
              />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Typography variant="h6" color="inherit" noWrap>
                Hi,  {props.username}
              </Typography>
            </Toolbar>
        }
      </AppBar>
      <main>
        <Button onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </Button>
        <div>
          <div className={classes.heroContent}>
            <Container maxWidth="lg">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Itinerary Overview
              </Typography>
              <div className={classes.info}>
                <div className={classes.left}>
                  <Typography
                    component="h3"
                    variant="h6"
                    align="left"
                    color="textPrimary"
                    gutterBottom>
                    {props.singleItinerary.title}
                  </Typography>
                  <br />
                  <Typography
                    component="h5"
                    variant="subtitle1"
                    align="left"
                    gutterBottom>
                    {props.singleItinerary.description}
                  </Typography>
                </div>
                <div className={classes.right}>
                  <Typography
                    component="h3"
                    variant="h6"
                    align="right"
                    gutterBottom>
                    <div className="itin-detail-icons">
                      <div className={classes.view}>
                        <VisibilityIcon className={classes.view} />

                        {props.singleItinerary.view}

                      </div>
                      {
                        props.singleItinerary.is_liked ?
                          <div className={classes.favorite}>
                            <FavoriteIcon
                              className={classes.favorite}
                              onClick={() => {
                                props.handleUnlikeItinerary(props.singleItinerary.id, props.token);
                              }}
                            />
                            {props.singleItinerary.like}
                          </div>
                          :
                          <div className={classes.favorite}>
                            <FavoriteBorderIcon
                              className={classes.favorite}
                              onClick={() => {
                                const datagram = {
                                  itinerary: props.singleItinerary.id
                                };
                                props.handleLikeItinerary(datagram, props.token);
                              }}
                            />
                            {props.singleItinerary.like}
                          </div>

                      }
                    </div>
                    {
                      props.singleItinerary === '' || props.singleItinerary.owner !== props.user_id ?
                        <div />
                        :
                        props.singleItinerary.is_public === true ?
                          <Button
                            onClick={() => {
                              const datagram = props.singleItinerary;
                              datagram.is_public = false;
                              props.handleSetPublicOrPrivateItin(datagram, datagram.id, props.token)
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Public
                        </Button>
                          :
                          <Button
                            onClick={() => {
                              const datagram = props.singleItinerary;
                              datagram.is_public = true;
                              props.handleSetPublicOrPrivateItin(datagram, datagram.id, props.token)
                            }}
                            variant="contained"
                            color={blueGrey[200]}
                          >
                            Private
                        </Button>
                    }
                  </Typography>

                </div>
              </div>
            </Container>
          </div>
        </div>
        <Grid container spacing={4} justify="center">
          {props.trips.map(trip => (
            <Grid item key={trip.id} xs={10} md={10} justify="center">
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Day {trip.day}{" "}
                  </Typography>
                </ExpansionPanelSummary>
                {trip.sites.map(site => (
                  <ExpansionPanelDetails key={site.site.id}>
                    <CardActionArea
                      onClick={() => {
                        history.push(
                          `/site/detail/${site.site.site_category}_${site.site.id}`
                        );
                      }}
                    >
                      <Card className={classes.card}>
                        <Hidden xsDown>
                          <CardMedia
                            className={classes.cardMedia}
                            image={site.site.photo}
                            title="Image title"
                          />
                        </Hidden>
                        <div className={classes.cardDetails}>
                          <CardContent className="place-container">
                            <Typography component="h2" variant="h5">
                              {site.site.name !== null &&
                                site.site.name !== undefined ? (
                                  <span>{site.site.name}</span>
                                ) : (
                                  <div />
                                )}
                              <span>&nbsp;</span>
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {site.site.city !== null &&
                                site.site.city !== undefined ? (
                                  <span>{site.site.city.city_name}</span>
                                ) : (
                                  <div />
                                )}
                            </Typography>
                            <Typography variant="subtitle1">
                              {site.site.description.slice(0, 180) + "..."}
                            </Typography>
                          </CardContent>
                          <div className="site-icon">
                            {site.site.site_category === "Attraction" ? (
                              <img src={attraction} />
                            ) : site.site.site_category === "Hotel" ? (
                              <img src={hotel} />
                            ) : (
                                  <img src={store} />
                                )}
                          </div>
                        </div>
                      </Card>
                    </CardActionArea>
                  </ExpansionPanelDetails>
                ))}
              </ExpansionPanel>
            </Grid>
          ))}
        </Grid>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Find us @
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <TwitterIcon />
          <FacebookIcon />
        </Typography>
        <Copyright />
      </footer>
    </React.Fragment>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    logged_in: state.auth.access_token !== undefined,
    user_id: state.auth.user_id,
    profile_pic: state.user.profile_pic,
    trips: state.itinerary.trips,
    singleItinerary: state.itinerary.singleItinerary
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {

  return {
    handleRestoreTrips(itinearyId, token) {
      dispatch(restore_trip(itinearyId, token))
    },
    hanldeGetCurrentItin(currentItin, token) {
      dispatch(restore_single_itinerary(currentItin, token));
    },
    handleSetPublicOrPrivateItin(newData, itineraryId, token) {
      dispatch(update_itinerary(newData, itineraryId, token))
    },
    handleLikeItinerary(newData, token) {
      dispatch(like_itinerary(newData, token))
    },
    handleUnlikeItinerary(itineraryId, token) {
      dispatch(unlike_itinerary(itineraryId, token))
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItineraryDetailView);
