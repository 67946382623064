export const CREATE_ITINERARY = 'createItinerary';
export const UPDATE_ITINERARY = 'updateItinerary';
export const DELETE_ITINERARY = 'deleteItinerary';
export const INIT_ITINERARY = 'initItinerary';
export const INIT_TRENDING_ITINERARY = 'initTrendingItinerary';
export const INIT_FAVORITE_ITINERARY = 'initFavoriteItinerary';
export const INIT_SINGLE_ITINERARY = 'initSingleItinerary';
export const RESTORE_ITIN_FAILURE = 'restoreItinFailure';
export const RESET_FIN_INIT_ITINERARY = 'resetFinInitItinerary';
export const CLEAR_INIT_ITINERARY = 'clearInitItinerary';
export const SET_CURRENT_ITINIERARY_INDEX = 'setCurrentItineraryIndex';
export const LIKE_ITINERARY = 'likeItinerary';
export const UNLIKE_ITINERARY = 'unlikeItinerary';
export const LIKE_TRENDING_ITINERARY = 'likeTrendingItinerary';
export const UNLIKE_TRENDING_ITINERARY = 'unlikeTrendingItinerary';
export const LIKE_TRENDING_ITINERARY_BY_ID = 'likeTrendingItineraryById';
export const UNLIKE_TRENDING_ITINERARY_BY_ID = 'unlikeTrendingItineraryById';

export const CREATE_TRIP = 'createTrip';
export const UPDATE_TRIP = 'updateTrip';
export const DELETE_TRIP = 'deleteTrip';
export const INIT_TRIP = 'initTrip';
export const CLEAR_INIT_TRIP = 'clearInitTrip';
export const CLEAR_INIT_SINGLE_TRIP = 'clearInitSingleTrip';
export const INIT_SINGLE_TRIP = 'initSingleTrip';

export const CREATE_TRIP_SITE = 'createTripSite';
export const CLEAR_INIT_SITE = 'clearInitSite';
export const FILTER_SITE = 'filterSite';
export const UPDATE_DAY_TRIP_SITE = 'updateDayTripSite';
export const DELETE_TRIP_SITE = 'deleteTripSite';
export const INIT_SITE_DETAIL = 'initSiteDetail';

export const CLEAR_INIT_CITY_LIST = 'clearInitCityList';
export const INIT_CITY_LIST = 'initCityList';
export const ADD_CITY_LIST = 'addCityList';
export const CLEAR_TEMPCITYLIST = 'clearTempCitylist'
