import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import PublishIcon from '@material-ui/icons/Publish';
import { connect } from "react-redux";
import { useEffect } from "react";
import { create_itinerary } from "../../actions/api/content";
import { actionCreators as itineraryActionCreators } from "../itinerary/store";

function CreateItinerary(props) {
  let history = useHistory();
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = React.useState();
  const [is_public, setIs_Public] = React.useState(false);
  const [description, setDescription] = React.useState();
  const [tempFile, setTempFile] = React.useState();
  const [created, setCreated] = React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const onFileChange = (event) => {
    return setTempFile(event.target.files[0]);
  }

  const handleClose = () => {
    setOpen(false);
  };

  function handleCreateItinerary() {
    const datagram = {
      title: title,
      description: description,
      is_public: is_public,
    };
    setCreated(true);
    console.log("triggered create itinerary");
    props.handleCreateItineraryDispatch(datagram, tempFile, props.token);
  }

  useEffect(() => {
    if (props.itineraries.length > 0 && created) {
      history.push(`/itinerary/${props.itineraries[props.itineraries.length - 1].id}`);
      setCreated(false);
    }
  }, [props.itineraries.length]);


  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create Itinerary
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New Itinerary</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-helperText"
            label="Itinerary Name"
            margin="normal"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            id="standard-multiline-static"
            label="Description"
            fullWidth
            margin="normal"
            multiline
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
          />
          <div>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button component="span">
                Upload Photo<PublishIcon />
              </Button>
            </label>
            {
              tempFile === undefined || tempFile === "" ?
                <p>No file change</p> : <p>Photo Uploaded </p>
            }
          </div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={is_public}
                  onChange={() => setIs_Public(!is_public)}
                />
              }
              label="Public"
            />{" "}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleCreateItinerary()
              props.handleClearInitTrip()
              props.handleClearInitItinerary()
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    logged_in: state.auth.access_token !== undefined,
    profile_pic: state.user.profile_pic,
    itineraries: state.itinerary.itineraries,
    currentItinIndex: state.itinerary.currentItinIndex,
  };
};

// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleCreateItineraryDispatch(datagram, tempFile, token) {
      dispatch(create_itinerary(datagram, tempFile, token));
    },
    handleClearInitTrip() {
      dispatch(itineraryActionCreators.clearInitTrip());
    },
    handleClearInitItinerary() {
      dispatch(itineraryActionCreators.clearInitItinerary());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItinerary);
