import React, { Component } from 'react'
import { login } from '../../actions/api/auth';
import { connect } from 'react-redux';

import LoginForm from './LoginForm';

class LoginPage extends Component {
    submit_login = (username, password) => {
        this.props.dispatch(login(username, password));
        this.props.handleMenuClose();
    };

    render() {
        return (
            <div>
                <LoginForm onSubmit={this.submit_login} logged_in={this.props.logged_in} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        logging_in: state.auth.logging_in || false,
    };
}

export default connect(mapStateToProps)(LoginPage);

