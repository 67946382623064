import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useEffect } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import { blue } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CardActionArea from "@material-ui/core/CardActionArea";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";
import { actionCreators as itineraryActionCreators } from "./store";
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { restore_site_by_city, create_trip_site } from "../../actions/api/content";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  root: {
    justifyContent: "center",
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function AddSite(props) {
  const classes = useStyles();
  let history = useHistory();
  const [siteType, setSiteType] = React.useState();
  const [siteToBeAdded, setSiteToBeAdded] = React.useState([]);

  function CreateTripSite() {
    let index = props.singleTrip.length > 0 ? props.singleTrip.length + 1 : 1;
    console.log(JSON.stringify(props.singleTrip));
    for (let i = 0; i < siteToBeAdded.length; i++) {
      const datagram = {
        site: siteToBeAdded[i],
        order: index++,
        day_trip: props.trips[props.currentTripIndex].id,
      }
      props.handleCreateTripSite(datagram, props.token);
    }
  }

  return (
    <div>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Add Site
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            Please select the sites you want to add
          </Typography>
          <Grid container spacing={12} justify="center">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Type of Site</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={siteType}
                onChange={(e) => setSiteType(e.target.value)}
                fullWidth
              >
                <MenuItem value="attraction" > Attraction</MenuItem>
                <MenuItem value="hotel" >Hotel</MenuItem>
                <MenuItem value="restaurant" > Restaurant</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => props.handleFilterSite(props.cityIdFilter, siteType)}
            >
              Filter
            </Button>
          </Grid>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={1}>
          {
            props.sites.map(site => (
              <Grid item key={site.site.id} xs={12} md={12} className="card">
                <CardActionArea>
                  <Card className={classes.card} >
                    <Hidden xsDown
                      onClick={() => {
                        history.push(`/site/detail/${site.site.site_category}_${site.site.id}`)
                      }}
                    >
                      <CardMedia
                        className={classes.cardMedia}
                        image={site.site.photo}
                        title="Image title"
                      />
                    </Hidden>
                    <div className={classes.cardDetails}
                      onClick={() => {
                        history.push(`/site/detail/${site.site.site_category}_${site.site.id}`)
                      }}
                    >
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          {site.site.name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {site.site.address}
                        </Typography>
                      </CardContent>
                    </div>
                    {
                      siteToBeAdded.includes(site.site.id) ?
                        <Button variant="contained" color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            let tempSite = siteToBeAdded;
                            tempSite = tempSite.filter(function (value, index, arr) {
                              return value !== site.site.id;
                            });
                            setSiteToBeAdded(tempSite);
                            history.push("#")
                          }}
                        >
                          <IndeterminateCheckBoxIcon
                          />
                        </Button>
                        :
                        <Button variant="contained" color="primary"
                          onClick={(e) => {
                            e.preventDefault()
                            let tempSite = siteToBeAdded;
                            tempSite.push(site.site.id);
                            setSiteToBeAdded(tempSite);
                            history.push("#")
                          }}
                        >
                          <AddCircleIcon />
                        </Button>
                    }
                  </Card>
                </CardActionArea>
              </Grid>
            ))
          }
        </Grid>
      </Container>
      {/* </TabPanel> */}
      <div className={classes.heroButtons}>
        <Grid container spacing={8} justify="center">
          <Grid item>
            <Button
              onClick={() => props.handleSwitchDayTrip()}
              size="large"
              variant="outlined"
              color="primary"
            >
              BACK
            </Button>
            <Button
              onClick={() => {
                props.handleSwitchDayTrip();
                CreateTripSite();
                itineraryActionCreators.clearInitSite();
                props.setCityIdFilter();
              }}
              size="large"
              variant="outlined"
              color="primary"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </div>
    </div >
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    sites: state.itinerary.sites,
    trips: state.itinerary.trips,
    singleTrip: state.itinerary.singleTrip,
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleFilterSite(cityId, siteType) {
      dispatch(restore_site_by_city(cityId, siteType));
    },
    handleCreateTripSite(datagram, token) {
      dispatch(create_trip_site(datagram, token))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSite);
