import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Toolbar from "@material-ui/core/Toolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { actionCreators as itineraryActionCreators } from "../itinerary/store";
import {
  restore_user_profile,
  restore_user_itinerary,
  delete_itinerary,
  restore_favorite_itinerary
} from "../../actions/api/content";
import EditIcon from "@material-ui/icons/Edit";
import EditProfile from "./EditProfile";
import { useHistory } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import CreateItinerary from "./CreateItinerary";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  button: {
    color: theme.palette.background.paper
  }
}));

const cards = [1, 2, 3, 4, 5, 6];

function UserProfile(props) {
  const classes = useStyles();

  useEffect(() => {
    props.handleGetUserInfo(props.user_id);
    props.handleRestoreItineraries(props.token);
  }, []);

  let history = useHistory();

  const [value, setValue] = React.useState(0);
  const [editStatus, setEditStatus] = React.useState(false);
  const [itinDetailStatus, setItinDetailStatus] = React.useState(false);
  const [viewItinDetailId, setViewItinDetailId] = React.useState();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [deleteTitle, setDeleteTitle] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleLoggedOut() {
    console.log("triggered home");
    history.push("/home");
  }

  return (
    <React.Fragment>
      {props.logged_in ? <div /> : handleLoggedOut()}
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <Avatar
            alt="User Avatar"
            className={classes.bigAvatar}
            src={props.profile_pic}
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Typography variant="h6" color="inherit" noWrap>
            Hi, {props.username}
          </Typography>
          {
            editStatus ? (
              <div />
            ) : (
                <Button
                  className={classes.button}
                  onClick={() => setEditStatus(true)}
                >
                  <EditIcon />
                </Button>
              )}
        </Toolbar>
      </AppBar>
      {
        editStatus ? (
          <EditProfile setEditStatus={setEditStatus} />
        ) :
          <main>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Itineraries" {...a11yProps(0)} />
                <Tab label="Favorites" {...a11yProps(1)} onClick={() => {
                  props.handleRestoreFavoriteItineraries(props.user_id)
                }} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className={classes.heroContent}>
                <Container maxWidth="sm">
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    My itineraries
                </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    bon voyage
                </Typography>
                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                      <Grid item>
                        <CreateItinerary />
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>
              <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                  {props.itineraries.map((itinerary, index) => (
                    <Grid item key={itinerary.id} xs={12} sm={6} md={4}>
                      <Card className={classes.card}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={itinerary.image}
                          title="Image title"
                          onClick={() => {
                            history.push(`/itinerary/detail/${itinerary.id}`);
                          }}
                        />
                        <CardContent className={classes.cardContent}
                          onClick={() => {
                            history.push(`/itinerary/detail/${itinerary.id}`);
                          }}>
                          <Typography gutterBottom variant="h5" component="h2">
                            {itinerary.title}
                          </Typography>
                          <Typography>
                            {new Date(itinerary.posted_on).toLocaleString()}
                          </Typography>
                          {itinerary.is_public ? (
                            <Typography>Public</Typography>
                          ) : (
                              <Typography>Private</Typography>
                            )}
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                              history.push(`/itinerary/detail/${itinerary.id}`);
                              setItinDetailStatus(true);
                              setViewItinDetailId(itinerary.id);
                            }}
                          >
                            View
                        </Button>
                          <Button
                            onClick={() => {
                              history.push(`/itinerary/${itinerary.id}`);
                            }}
                            size="small" color="primary">
                            Edit
                        </Button>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => {
                              setDeleteOpen(true);
                              setDeleteId(itinerary.id);
                              setDeleteTitle(itinerary.title);
                            }}
                          >
                            Delete
                        </Button>
                          <Dialog
                            open={deleteOpen}
                            onClose={() => setDeleteOpen(!deleteOpen)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Delete City"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are you sure to delete {deleteTitle}?
                                                          {" "}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setDeleteOpen(!deleteOpen);
                                  props.handleDeleteItineraries(
                                    deleteId,
                                    props.token
                                  );
                                  setDeleteId("");
                                  setDeleteTitle("");
                                }}
                                color="primary"
                              >
                                Delete                             {" "}
                              </Button>
                              <Button
                                onClick={() => {
                                  setDeleteOpen(!deleteOpen);
                                }}
                                color="primary"
                                autoFocus
                              >
                                Cancel                             {" "}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={classes.heroContent}>
                <Container maxWidth="sm">
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    My favorites
                  {/* user name should be showing up here */}
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    bon voyage
                </Typography>
                </Container>
              </div>
              <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                  {
                    props.favoriteItineraries === undefined || props.favoriteItineraries === "" ?
                      <div />
                      :
                      props.favoriteItineraries.map((itinerary, index) => (
                        <Grid item key={itinerary.id} xs={12} sm={6} md={4}>
                          <Card className={classes.card}>
                            <CardMedia
                              className={classes.cardMedia}
                              image={itinerary.itinerary.image}
                              title="Image title"
                              onClick={() => {
                                history.push(`/itinerary/detail/${itinerary.itinerary.id}`);
                              }}
                            />
                            <CardContent className={classes.cardContent}
                              onClick={() => {
                                history.push(`/itinerary/detail/${itinerary.itinerary.id}`);
                              }}>
                              <Typography gutterBottom variant="h5" component="h2">
                                {itinerary.itinerary.title}
                              </Typography>
                              <Typography>
                                {new Date(itinerary.itinerary.posted_on).toLocaleString()}
                              </Typography>
                              {itinerary.itinerary.is_public ? (
                                <Typography>Public</Typography>
                              ) : (
                                  <Typography>Private</Typography>
                                )}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                </Grid>
              </Container>
            </TabPanel>
          </main>
      }
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Find us @
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <TwitterIcon />
          <FacebookIcon />
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    logged_in: state.auth.access_token !== undefined,
    user_id: state.auth.user_id,
    profile_pic: state.user.profile_pic,
    itineraries: state.itinerary.itineraries,
    favoriteItineraries: state.itinerary.favoriteItineraries
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleGetUserInfo(user_id) {
      dispatch(restore_user_profile(user_id));
    },
    handleRestoreItineraries(token) {
      dispatch(restore_user_itinerary(token));
    },
    handleDeleteItineraries(itineraryId, token) {
      dispatch(delete_itinerary(itineraryId, token));
    },
    handleSetCurrentItinIndex(index) {
      dispatch(itineraryActionCreators.setCurrentItineraryIndex(index));
    },
    handleRestoreFavoriteItineraries(user_id) {
      dispatch(restore_favorite_itinerary(user_id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
