import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from './Container';
import { refresh_token } from '../../actions/api/auth';

class AppManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  stop_loading = () => {
    this.setState((state) => Object.assign({}, state, {
      loading: false
    }));
  };

  componentDidMount() {
    const stored_access_token = localStorage.getItem("access_token") || "";
    const stored_refresh_token = localStorage.getItem("refresh_token") || "";
    // console.log("app manageer" + stored_access_token);
    if (stored_access_token !== undefined) {
      this.props.dispatch(refresh_token(stored_refresh_token))
        .finally(this.stop_loading);
    } else {
      this.stop_loading();
    }
  }

  // componentDidUpdate() {
  //   // localStorage.setItem("token", this.props.token || "");
  //   // localStorage.setItem("refresh_token", this.props.refresh_token || "");
  // }

  render() {
    if (this.state.loading) {
      return (
        <div>Loading</div>
      );
    } else {
      let is_array = Array.isArray(this.props.children);
      let children = this.props.children;
      if (!is_array) {
        children = [children];
      }
      return (
        <Container key={this.props.access_token}>
          {[...children]}
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    // "token": state.auth.token,
    "access-token": state.auth.access_token,
    "refresh-token": state.auth.refresh_token,
    "_change": state.auth._change,
  };
}

export default connect(mapStateToProps)(AppManager);

