import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";
import { blue } from "@material-ui/core/colors";
import { restore_city } from "../../actions/api/content";
import { actionCreators as itineraryActionCreators } from "./store";


const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  },
  root: {
    justifyContent: "center"
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  }
  // root: {
  //     flexGrow: 1,
  //     backgroundColor: theme.palette.background.paper,
  // },
}));

function AddCity(props) {
  const classes = useStyles();

  function checkCity(cityId) {
    if (props.trips[props.currentTripIndex] === undefined) return true;
    if (props.trips[props.currentTripIndex].citylist === undefined) return false;
    for (let i = 0; i < props.trips[props.currentTripIndex].citylist.length; i++) {
      if (props.trips[props.currentTripIndex].citylist[i].id === cityId) {
        return true;
      }
    }
    return false;
  }
  useEffect(() => {
    props.handleRestoreCity();
  }, []);

  return (
    <div>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Add Cities
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            paragraph
          >
            Please select the cities you want to add
          </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        {/* End hero unit */}
        <Grid container spacing={4}>
          {
            props.cities.map(city => (
              <Grid item key={city.id} xs={12} sm={6} md={4}>
                {
                  !checkCity(city.id) ?
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={city.photo}
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {city.city_name}
                        </Typography>
                        <Typography>{city.country_name}</Typography>
                      </CardContent>
                      <CardActions classes={{ root: classes.root }}>
                        {console.log("city is" + JSON.stringify(city))}
                        <Icon className={classes.iconHover} color="secondary"
                          onClick={() => {
                            props.handleSwitchDayTrip();
                            console.log("why " + JSON.stringify(city) + props.trips.length + " " + props.currentTripIndex);
                            props.handleAddCityList(props.trips[props.currentTripIndex].id, city);
                          }}>
                          add_city
                      </Icon>
                      </CardActions>
                    </Card>
                    :
                    <div />
                }
              </Grid>
            ))}
        </Grid>
      </Container>
      <div className={classes.heroButtons}>
        <Grid container spacing={8} justify="center">
          <Grid item>
            <Button
              onClick={() => props.handleSwitchDayTrip()}
              size="large"
              variant="outlined"
              color="primary"
            >
              BACK
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    // TODO: get user id to have itineraries
    username: state.auth.username,
    token: state.auth.access_token,
    cities: state.admin.cities,
    trips: state.itinerary.trips,
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreCity() {
      dispatch(restore_city());
    },
    handleAddCityList(tripId, city) {
      dispatch(itineraryActionCreators.addCityList(tripId, city))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCity);
