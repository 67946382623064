import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  restore_user_profile,
  restore_featured,
  restore_public_itinerary,
  update_dashboard_data,
  create_dashboard_data,
  delete_dashboard_data
} from "../../actions/api/content";
import Image from "material-ui-image";

import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContentText from "@material-ui/core/DialogContentText";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import RemoveIcon from "@material-ui/icons/Remove";
import StarIcon from "@material-ui/icons/Star";

const useStyles = makeStyles(theme => ({
  // seeMore: {
  //   marginTop: theme.spacing(3)
  // },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  textField: {
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12)
  },
  container: {
    paddingTop: theme.spacing(12)
    // paddingBottom: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  card2: {
    height: "100%"
    // flexDirection: "column"
  },
  cardMedia2: {
    paddingTop: "33.33%"
  },
  cardContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "80%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function FeaturedTrip(props) {
  //   useEffect(() => {
  //     props.handleRestoreFeatured();
  //   }, []);

  useEffect(() => {
    console.log("trigger restore itin in itin component");
    props.handleRestoreFeatured();
    props.handleRestoreItineraries(props.token);
  }, []);
  const card = 1;
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const featured = props.featured;

  //   const [tempFile, setTempFile] = React.useState();
  const [id, setId] = React.useState();
  const [itineraryId, setItineraryId] = React.useState();
  const [itinerary, setItinerary] = React.useState();

  //   const [url, setURL] = React.useState();

  //   const [editOpen, setEditOpen] = React.useState(false);
  //   const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  //   const [state, setState] = React.useState({
  //     name: 0
  //     // name: "hai"
  //   });

  const handleCreateFeatured = itineraryId => {
    const datagram = {
      //   id: id,
      itinerary: itineraryId
    };
    console.log(datagram);
    props.handleCreateFeaturedDispatch(datagram, props.token);
    // setCreateOpen(!createOpen);
    setId("");
    setItinerary("");
  };

  return (
    <React.Fragment>
      <Paper className={fixedHeightPaper}>
        <Title>Featured Itineraries</Title>
        <Table class="dashboard-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              {/* <TableCell>Intinerary Id</TableCell> */}
              <TableCell>Intinerary Title</TableCell>
              <TableCell>Views</TableCell>
              <TableCell>Photo</TableCell>
              {/* <TableCell>URL</TableCell>
              <TableCell>Photo</TableCell> */}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {featured === undefined ? (
              <div>nothing!</div>
            ) : (
              props.featured.map(trip => (
                <TableRow key={trip.id}>
                  <TableCell>{trip.itinerary.id}</TableCell>
                  {/* <TableCell>{trip.itinerary.id}</TableCell> */}
                  <TableCell>{trip.itinerary.title}</TableCell>
                  <TableCell>{trip.itinerary.view}</TableCell>
                  <TableCell>
                    <Image
                      src={trip.itinerary.image}
                      id="itinerary-image"
                      aspectRatio={16 / 9}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      onClick={() => {
                        setDeleteOpen(true);
                        setId(trip.id);
                        setItineraryId(trip.itinerary.id);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Dialog
                      open={deleteOpen}
                      onClose={() => setDeleteOpen(!deleteOpen)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to delete itinerary {itineraryId}?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            props.handleDeleteFeaturedDispatch(
                              //   itineraryId,
                              id,
                              props.token
                            );
                            setId("");
                            setItineraryId("");
                          }}
                          color="primary"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => setDeleteOpen(!deleteOpen)}
                          color="primary"
                          autoFocus
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
      <br />
      <Paper className={fixedHeightPaper}>
        <Title>All Public Itineraries</Title>
        <Table class="dashboard-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              {/* <TableCell>Intinerary Id</TableCell> */}
              <TableCell>Intinerary Title</TableCell>
              <TableCell>Views</TableCell>
              <TableCell>Photo</TableCell>
              {/* <TableCell>URL</TableCell>
              <TableCell>Photo</TableCell> */}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.itineraries === undefined ? (
              <div>nothing!</div>
            ) : (
              props.itineraries.map(itinerary => (
                <TableRow key={itinerary.id}>
                  <TableCell>{itinerary.id}</TableCell>
                  {/* <TableCell>{trip.itinerary.id}</TableCell> */}
                  <TableCell>{itinerary.title}</TableCell>
                  <TableCell>{itinerary.view}</TableCell>
                  <TableCell>
                    <Image
                      src={itinerary.image}
                      id="itinerary-image"
                      aspectRatio={16 / 9}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      onClick={() => {
                        // setDeleteOpen(true);
                        handleCreateFeatured(itinerary.id);
                      }}
                    >
                      <StarIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
    // </Paper>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    featured: state.admin.featured,
    token: state.auth.access_token,
    itineraries: state.itinerary.itineraries
  };
};

// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreFeatured() {
      dispatch(restore_featured());
    },
    handleRestoreItineraries(token) {
      dispatch(restore_public_itinerary(token));
    },
    handleCreateFeaturedDispatch(datagram, token) {
      dispatch(create_dashboard_data(datagram, "", "featured", token));
    },
    handleUpdateFeaturedDispatch(datagram, id, token) {
      dispatch(update_dashboard_data(datagram, "", id, "featured", token));
    },
    handleDeleteFeaturedDispatch(id, token) {
      dispatch(delete_dashboard_data(id, "featured", token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedTrip);

// // react-redux main API 1: map store.state to props
// const mapStateToProps = (state) => {
//     return {
//     }
// }

// // react-redux main API 2: map store.dispatch to props
// const mapDispatchToProps = (dispatch) => { }
// export default connect(mapStateToProps, mapDispatchToProps)(FeaturedTrip);
