import { Component } from 'react';

class Container extends Component {
  render() {
    return ([...this.props.children]);
  }
}

export default Container;

