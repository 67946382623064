import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  restore_city,
  restore_restaurant,
  update_dashboard_data,
  create_dashboard_data,
  delete_dashboard_data
} from "../../actions/api/content";
import Image from "material-ui-image";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import PublishIcon from "@material-ui/icons/Publish";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
  mainpaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function Restaurant(props) {
  useEffect(() => {
    props.handleRestoreRestaurant();
    props.handleRestoreCity();
  }, []);

  const classes = useStyles();
  const fixedHeightPaper = classes.mainpaper;

  const [tempFile, setTempFile] = React.useState();
  const [id, setId] = React.useState();
  const [name, setName] = React.useState();
  const [cityName, setCityName] = React.useState();
  const [cityId, setCityId] = React.useState();
  const [latitude, setLatitude] = React.useState();
  const [longitude, setLongitude] = React.useState();
  const [url, setUrl] = React.useState();
  const [address, setAddress] = React.useState();
  const [description, setDescription] = React.useState();
  const [open_at, setOpen_At] = React.useState();
  const [category, setCategory] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const setDefault = () => {
    setId("");
    setCityName("");
    setName("");
    setCityId("");
    setLatitude("");
    setLongitude("");
    setUrl("");
    setAddress("");
    setDescription("");
    setCategory("");
    setTempFile("");
    setOpen_At("");
  };

  const handleSubmitEdit = () => {
    const datagram = {
      "site.city": cityId,
      "site.name": name,
      "site.latitude": latitude,
      "site.longitude": longitude,
      "site.site_category": "Restaurant",
      "site.url": url,
      "site.address": address,
      "site.description": description,
      category: category,
      open_at: open_at
    };
    console.log(datagram);
    props.handleUpdateDispatch(datagram, tempFile, id, props.token);
    setEditOpen(!editOpen);
    setDefault();
  };

  const handleCreate = () => {
    const datagram = {
      "site.city": cityId,
      "site.name": name,
      "site.latitude": latitude,
      "site.longitude": longitude,
      "site.site_category": "Restaurant",
      "site.url": url,
      "site.address": address,
      "site.description": description,
      category: category,
      open_at: open_at
    };
    props.handleCreateDispatch(datagram, tempFile, props.token);
    setCreateOpen(!createOpen);
    setDefault();
  };

  const onFileChange = event => {
    return setTempFile(event.target.files[0]);
  };

  return (
    <Paper className={fixedHeightPaper}>
      <React.Fragment>
        <Title>
          Restaurants           
          <Button color="primary" onClick={() => setCreateOpen(!createOpen)}>
            <AddIcon />
          </Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={createOpen}
            onClose={() => setCreateOpen(!createOpen)}
          >
            <DialogTitle>Creating Restaurant</DialogTitle>
            <DialogContent>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-required-label">
                  City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cityId}
                  onChange={e => setCityId(e.target.value)}
                  fullWidth
                >
                  {props.cities.map(city => (
                    <MenuItem value={city.id}>{city.city_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-helperText"
                label="Open At"
                type="number"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setOpen_At(e.target.value)}
              />
              <p> </p>
              <TextField
                id="outlined-helperText"
                label="Name"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setName(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="Category"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setCategory(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="Longitude"
                type="number"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setLongitude(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="Latitude"
                type="number"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setLatitude(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="Address"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={e => setAddress(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="URL"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={e => setUrl(e.target.value)}
              />
              <TextField
                id="standard-multiline-static"
                label="Description"
                fullWidth
                margin="normal"
                multiline
                variant="outlined"
                onChange={e => setDescription(e.target.value)}
              />
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span">
                    Upload Photo
                    <PublishIcon />
                  </Button>
                </label>
                {tempFile === undefined || tempFile === "" ? (
                  <p>No file change</p>
                ) : (
                  <p>Photo Uploaded </p>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCreateOpen(!createOpen);
                  setDefault();
                }}
                color="primary"
              >
                Cancel               
              </Button>
              <Button onClick={() => handleCreate()} color="primary">
                Ok               
              </Button>
            </DialogActions>
          </Dialog>
        </Title>
        <Table class="dashboard-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.restaurants === undefined ? (
              <div />
            ) : (
              props.restaurants.map(restaurant => (
                <TableRow key={restaurant.site.id}>
                  <TableCell>{restaurant.site.name}</TableCell>
                  <TableCell>{restaurant.site.city.city_name}</TableCell>
                  <TableCell>{restaurant.site.address}</TableCell>
                  <TableCell>
                    <Image
                      src={restaurant.site.photo}
                      id="restaurant-image"
                      aspectRatio={16 / 9}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {/* Edit button */}
                    <Button
                      color="primary"
                      onClick={() => {
                        setEditOpen(!editOpen);
                        setId(restaurant.site.id);
                        setName(restaurant.site.name);
                        setLatitude(restaurant.site.latitude);
                        setLongitude(restaurant.site.longitude);
                        setUrl(restaurant.site.url);
                        setAddress(restaurant.site.address);
                        setDescription(restaurant.site.description);
                        setCategory(restaurant.category);
                        setCityId(restaurant.site.city.id);
                        setCityName(restaurant.site.city.city_name);
                        setOpen_At(restaurant.open_at);
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      open={editOpen}
                      onClose={() => setEditOpen(!editOpen)}
                    >
                      <DialogTitle>
                        Editing Restaurant {restaurant.site.name}
                      </DialogTitle>
                      <DialogContent>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-required-label">
                            City
                          </InputLabel>
                          <Select
                            value={cityId}
                            onChange={e => setCityId(e.target.value)}
                            className={classes.selectEmpty}
                          >
                            {props.cities.map(city => (
                              <MenuItem value={city.id}>
                                {city.city_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          id="outlined-helperText"
                          label="Open At"
                          type="number"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          defaultValue={open_at}
                          onChange={e => setOpen_At(e.target.value)}
                        />
                        <p></p>
                        <TextField
                          id="outlined-helperText"
                          label="Name"
                          className={classes.textField}
                          margin="normal"
                          defaultValue={name}
                          variant="outlined"
                          onChange={e => setName(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="Category"
                          className={classes.textField}
                          defaultValue={category}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setCategory(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="Longitude"
                          type="number"
                          defaultValue={longitude}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setLongitude(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="Latitude"
                          type="number"
                          defaultValue={latitude}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setLatitude(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="URL"
                          fullWidth
                          defaultValue={url}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setUrl(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="Address"
                          margin="normal"
                          fullWidth
                          defaultValue={address}
                          variant="outlined"
                          onChange={e => setAddress(e.target.value)}
                        />
                        <TextField
                          id="standard-multiline-static"
                          label="Description"
                          defaultValue={description}
                          fullWidth
                          margin="normal"
                          multiline
                          variant="outlined"
                          onChange={e => setDescription(e.target.value)}
                        />
                        <div>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={onFileChange}
                          />
                          <label htmlFor="raised-button-file">
                            <Button component="span">
                              Upload Photo
                              <PublishIcon />
                            </Button>
                          </label>
                          {tempFile === undefined || tempFile === "" ? (
                            <p>No file change</p>
                          ) : (
                            <p>Photo Uploaded </p>
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setEditOpen(!editOpen);
                            setDefault();
                          }}
                          color="primary"
                        >
                          Cancel                             
                        </Button>
                        <Button
                          onClick={() => handleSubmitEdit()}
                          color="primary"
                        >
                          Ok                             
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button
                      color="primary"
                      onClick={() => {
                        setDeleteOpen(true);
                        setId(restaurant.site.id);
                        setName(restaurant.site.name);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    {/* delete button */}
                    <Dialog
                      open={deleteOpen}
                      onClose={() => setDeleteOpen(!deleteOpen)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete City"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to delete {name}?
                                                      
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            props.handleDelete(id, props.token);
                            setDefault();
                          }}
                          color="primary"
                        >
                          Delete                             
                        </Button>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            setDefault();
                          }}
                          color="primary"
                          autoFocus
                        >
                          Cancel                             
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    </Paper>
  );
}
const mapStateToProps = state => {
  return {
    restaurants: state.admin.restaurants,
    cities: state.admin.cities,
    token: state.auth.access_token
  };
};

// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreRestaurant() {
      dispatch(restore_restaurant());
    },
    handleRestoreCity() {
      dispatch(restore_city());
    },
    handleCreateDispatch(datagram, tempFile, token) {
      dispatch(create_dashboard_data(datagram, tempFile, "restaurant", token));
    },
    handleUpdateDispatch(datagram, tempFile, id, token) {
      dispatch(
        update_dashboard_data(datagram, tempFile, id, "restaurant", token)
      );
    },
    handleDelete(id, token) {
      dispatch(delete_dashboard_data(id, "restaurant", token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
