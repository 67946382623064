import { connect } from "react-redux";
import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import { Slide } from "react-slideshow-image";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { pink, indigo } from '@material-ui/core/colors';
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import {
  restore_highlight,
  restore_trending_itinerary,
  restore_featured,
  like_itinerary_home,
  unlike_itinerary_home,
  unlike_itinerary_trending,
  like_itinerary_trending
} from "../actions/api/content";
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarTitle: {
    flex: 1
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto"
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/user/erondu)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex",
    height: "140px"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160,
    right: 0
  },

  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200]
  },
  sidebarSection: {
    marginTop: theme.spacing(3)
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0)
  },
  root: {
    width: "100%",
    display: "flex",
    color: "black",
    textAlign: "left",
    alignItems: "center",
    backgroundColor: "transparent",
    position: "absolute",
    bottom: "-20px",
    padding: "23px 30px",
    fontWeight: "lighter",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))"
  },
  searchBar: {
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 2
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  card2: {
    height: "290px",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  cardMedia2: {
    paddingTop: "56.25%", // 16:9
  },
  favorite: {
    color: pink[400],
    margin: "0 5px",
    float: "left"
  },
  view: {
    color: indigo[400],
    margin: "0 5px",
    float: "left"
  },
  itinBtn: {
    bottom: "0",
    position: "absolute",
    right: "15px",
  },
  itinBtnTrend: {
    bottom: "0",
    position: "absolute",
    left: "15px",
  },
  cardContent: {
    flexGrow: 1
  },
  slidecontainer: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/user/erondu)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  eachslide: {
    height: "100%"
  },
  slidePic: {
    backgroundImage: "url(https://source.unsplash.com/user/erondu)",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    bottom: 0
  },
  headerText: {
    fontFamily: "sans-serif",
    color: "white",
    opacity: 0.8
  }
}));

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
};

function Slideshow(props) {
  const classes = useStyles();

  return (
    <div>
      <Slide {...properties}>
        {props.highlights.map(highlight => (
          <div className={classes.eachslide}>
            <div
              onClick={() => {
                window.location.href = highlight.url;
              }}
              className={classes.slidePic}
              style={{ backgroundImage: `url(${highlight.photo})` }}
            >
              <a href={highlight.url} />
              <div className={classes.searchBar}>
                <Paper className={classes.root}>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    className={classes.headerText}
                  >
                    {highlight.headertext}
                  </Typography>
                </Paper>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
}

function Home(props) {
  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    props.handleRestoreHighlight();
    props.handleRestoreTrendingItins(props.token);
    props.handleRestoreFeatured(props.token);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          {/* <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            className={classes.toolbarTitle}
          >
            TripHub
          </Typography> */}
          <div className="logo">
            <img
              src="https://s2.ax1x.com/2019/12/05/Q3Y876.png"
              alt="Q3Y876.png"
              border="0"
            />
          </div>
        </Toolbar>
        <main>
          <Slideshow highlights={props.highlights} />
          <Grid container spacing={5} className={classes.mainGrid}>
            {/* Main content */}
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Featured
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={4}>
              {
                props.featured === undefined ?
                  <div /> : (
                    props.featured.map((itinerary, index) => (
                      <Grid item key={itinerary.id} xs={12} sm={8} md={4}>
                        <CardActionArea>
                          <Card className={classes.card2}>
                            <CardMedia
                              onClick={() => {
                                history.push(`/itinerary/detail/${itinerary.itinerary.id}`);
                              }}
                              className={classes.cardMedia2}
                              image={itinerary.itinerary.image}
                            />
                            <CardContent className={classes.cardContent}>
                              <div onClick={() => {
                                history.push(`/itinerary/detail/${itinerary.itinerary.id}`);
                              }}>
                                <Typography gutterBottom variant="h5" component="h2">

                                  {
                                    itinerary.itinerary.title.length > 22 ?
                                      itinerary.itinerary.title.slice(0, 22) + ".."
                                      :
                                      itinerary.itinerary.title
                                  }
                                </Typography>
                                <Typography variant="body2" >{itinerary.itinerary.description.slice(0, 60) + "..."}</Typography>
                              </div>
                              <Typography
                                align="center"
                                className={classes.itinBtn}
                                gutterBottom>
                                <div className={classes.view}>
                                  <VisibilityIcon className={classes.view} />
                                  {itinerary.itinerary.view}
                                </div>
                                {
                                  itinerary.itinerary.is_liked === true ?
                                    <div className={classes.favorite}>
                                      <FavoriteIcon
                                        className={classes.favorite}
                                        onClick={() => {
                                          props.handleUnlikeItinerary(itinerary.itinerary.id, index, props.token);
                                        }}
                                      />
                                      {itinerary.itinerary.like}
                                    </div>
                                    :
                                    <div className={classes.favorite}>
                                      <FavoriteBorderIcon
                                        className={classes.favorite}
                                        onClick={() => {
                                          const datagram = {
                                            itinerary: itinerary.itinerary.id
                                          };
                                          props.handleLikeItinerary(datagram, index, props.token);
                                        }}
                                      />
                                      {itinerary.itinerary.like}
                                    </div>
                                }
                              </Typography>
                            </CardContent>
                          </Card>
                        </CardActionArea>
                      </Grid>
                    ))
                  )}
            </Grid>
          </Container>
          <Grid container spacing={5} className={classes.mainGrid}>
            {/* Main content */}
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Trending
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          {/* End main featured post */}
          {/* Sub featured posts */}
          <Grid container spacing={4}>
            {
              props.trendingItineraries.length > 0 ? (
                props.trendingItineraries.map((itinerary, index) => (
                  <Grid item key={itinerary.title} xs={10} md={6}>
                    <CardActionArea>
                      <Card className={classes.card}>
                        <CardContent className={classes.cardDetails}
                          onClick={() => {
                            history.push(`/itinerary/detail/${itinerary.id}`);
                          }} >
                          <Typography component="h2" variant="h5">
                            {itinerary.title}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                            {itinerary.description.slice(0, 100) + "..."}
                          </Typography>
                        </CardContent>
                        <Hidden xsDown>
                          <CardMedia
                            className={classes.cardMedia}
                            image={itinerary.image}
                            title="Image title"
                            onClick={() => {
                              history.push(`/itinerary/detail/${itinerary.id}`);
                            }}
                          />
                        </Hidden>
                        <Typography
                          align="center"
                          className={classes.itinBtnTrend}
                          gutterBottom>
                          <div className={classes.view}>
                            <VisibilityIcon className={classes.view} />
                            {itinerary.view}
                          </div>
                          {
                            itinerary.is_liked === true ?
                              <div className={classes.favorite}>
                                <FavoriteIcon
                                  className={classes.favorite}
                                  onClick={() => {
                                    props.handleUnlikeTrendingItinerary(itinerary.id, index, props.token);
                                  }}
                                />
                                {itinerary.like}
                              </div>
                              :
                              <div className={classes.favorite}>
                                <FavoriteBorderIcon
                                  className={classes.favorite}
                                  onClick={() => {
                                    const datagram = {
                                      itinerary: itinerary.id
                                    };
                                    props.handleLikeTrendingItinerary(datagram, index, props.token);
                                  }}
                                />
                                {itinerary.like}
                              </div>
                          }
                        </Typography>
                      </Card>
                    </CardActionArea>
                  </Grid>
                ))
              ) :
                <div />
            }
          </Grid>
        </main>
      </Container>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Find us @
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <TwitterIcon />
          <FacebookIcon />
        </Typography>
        <Copyright />
      </footer>
    </React.Fragment >
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    token: state.auth.access_token,
    highlights: state.admin.highlights,
    featured: state.admin.featured,
    trendingItineraries: state.itinerary.trendingItineraries
  };
};

// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreFeatured(token) {
      dispatch(restore_featured(token));
    },
    handleRestoreHighlight() {
      dispatch(restore_highlight());
    },
    handleRestoreTrendingItins(token) {
      dispatch(restore_trending_itinerary(token));
    },
    handleLikeItinerary(newData, itinIndex, token) {
      dispatch(like_itinerary_home(newData, itinIndex, token))
    },
    handleUnlikeItinerary(itineraryId, itinIndex, token) {
      dispatch(unlike_itinerary_home(itineraryId, itinIndex, token))
    },
    handleLikeTrendingItinerary(newData, itinIndex, token) {
      dispatch(like_itinerary_trending(newData, itinIndex, token))
    },
    handleUnlikeTrendingItinerary(itineraryId, itinIndex, token) {
      dispatch(unlike_itinerary_trending(itineraryId, itinIndex, token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
