import * as actionTypes from "./actionTypes";

const defaultState = {
  cities: [],
  restaurants: [],
  attractions: [],
  hotels: [],
  highlights: [],
  users: []
  // groups: []
};

export default (state = defaultState, action) => {
  /**
   * General
   */
  if (action.type === actionTypes.UPDATE_DASHBOARD_DATA) {
    const newState = JSON.parse(JSON.stringify(state));
    const dataType = action.dataType;
    console.log("reducer update data" + action.datagram);
    let currentArray = [];
    if (dataType === "city") {
      currentArray = newState.cities; // changing the original array
    } else if (dataType === "attraction") {
      currentArray = newState.attractions;
    } else if (dataType === "restaurant") {
      currentArray = newState.restaurants;
    } else if (dataType === "hotel") {
      currentArray = newState.hotels;
    } else if (dataType === "user") {
      currentArray = newState.users;
    } else if (dataType === "featured") {
      currentArray = newState.featured;
    } else if (dataType === "highlight") {
      currentArray = newState.highlights;
    }
    console.log("---------------");
    console.log(action.id);
    console.log("---------------");
    console.log(action.datagram);
    update_data(action.datagram, action.id, currentArray);
    return newState;
  }

  if (action.type === actionTypes.CREATE_DASHBOARD_DATA) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log(JSON.stringify(state) + "****");
    const dataType = action.dataType;
    console.log("reducer create data" + action.datagram);
    if (dataType === "city") {
      newState.cities.push(action.datagram);
    } else if (dataType === "attraction") {
      newState.attractions.push(action.datagram);
    } else if (dataType === "restaurant") {
      newState.restaurants.push(action.datagram);
    } else if (dataType === "hotel") {
      newState.hotels.push(action.datagram);
    } else if (dataType === "featured") {
      newState.featured.push(action.datagram);
    } else if (dataType === "highlight") {
      newState.highlight.push(action.datagram);
    }
    return newState;
  }

  if (action.type === actionTypes.DELETE_DASHBOARD_DATA) {
    const newState = JSON.parse(JSON.stringify(state));
    const dataType = action.dataType;
    console.log("reducer delete data" + action.id);
    let currentArray = [];
    if (dataType === "city") {
      currentArray = newState.cities; // changing the original array
    } else if (dataType === "restaurant") {
      currentArray = newState.restaurants;
    } else if (dataType === "hotel") {
      currentArray = newState.hotels;
    } else if (dataType === "attraction") {
      currentArray = newState.attractions;
    } else if (dataType === "user") {
      currentArray = newState.users;
    } else if (dataType === "featured") {
      currentArray = newState.featured;
    } else if (dataType === "highlight") {
      currentArray = newState.highlight;
    }
    delete_data(action.id, currentArray);
    return newState;
  }

  /**
   * Cities
   */
  if (action.type === actionTypes.CLEAR_INIT_CITIES) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.cities = [];
    return newState;
  }

  if (action.type === actionTypes.INIT_CITIES) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.cities.push(action.cityDatagram);
    return newState;
  }

  /**
   * Users
   */
  if (action.type === actionTypes.INIT_USERS) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.userDatagram));
    newState.users.push(action.userDatagram);
    return newState;
  }

  if (action.type === actionTypes.CLEAR_INIT_USERS) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.userDatagram));
    newState.users = [];
    return newState;
  }

  /**
   * Featured
   */
  if (action.type === actionTypes.INIT_FEATURED) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.featuredDatagram));
    newState.featured.push(action.featuredDatagram);
    return newState;
  }

  if (action.type === actionTypes.CLEAR_INIT_FEATURED) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.featured = [];
    return newState;
  }

  /**
   * Highlights
   */
  if (action.type === actionTypes.INIT_HIGHLIGHTS) {
    console.log("reducer something here?");
    const newState = JSON.parse(JSON.stringify(state));

    console.log("reducer" + JSON.stringify(action.highlightDatagram));
    newState.highlights = action.highlightDatagram;
    return newState;
  }

  if (action.type === actionTypes.CLEAR_INIT_HIGHLIGHTS) {
    console.log("reducer cleared highlight");
    const newState = JSON.parse(JSON.stringify(state));
    newState.highlights = [];
    return newState;
  }

  /**
   * Attractions
   */
  if (action.type === actionTypes.CLEAR_INIT_ATTRACTIONS) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.attractions = [];
    return newState;
  }

  if (action.type === actionTypes.INIT_ATTRACTIONS) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.attractionDatagram));
    console.log(JSON.stringify(action.attractionDatagram.site));
    console.log(JSON.stringify(action.attractionDatagram.site.city));
    let newDatagram = action.attractionDatagram;
    newDatagram.site = newDatagram.site;
    newDatagram.site.city = newDatagram.site.city;
    newState.attractions.push(newDatagram);
    return newState;
  }

  /**
   * Restaurants
   */
  if (action.type === actionTypes.CLEAR_INIT_RESTAURANTS) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.restaurants = [];
    return newState;
  }

  if (action.type === actionTypes.INIT_RESTAURANTS) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.restaurantDatagram));
    newState.restaurants.push(action.restaurantDatagram);
    return newState;
  }

  /**
   * Hotels
   */
  if (action.type === actionTypes.CLEAR_INIT_HOTELS) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.hotels = [];
    return newState;
  }

  if (action.type === actionTypes.INIT_HOTELS) {
    const newState = JSON.parse(JSON.stringify(state));
    console.log("reducer" + JSON.stringify(action.hotelDatagram));
    newState.hotels.push(action.hotelDatagram);
    return newState;
  }

  // if (action.type === actionTypes.INIT_GROUPS) {
  //   const newState = JSON.parse(JSON.stringify(state));
  //   console.log("reducer" + JSON.stringify(action.groupDatagram));
  //   newState.groups.push(action.groupDatagram);
  //   return newState;
  // }

  /**
 * Like
 */
  if (action.type === actionTypes.UNLIKE_ITINERARY_HOME) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.featured[action.index].itinerary.is_liked = false;
    newState.featured[action.index].itinerary.like--;
    return newState;
  }

  if (action.type === actionTypes.LIKE_ITINERARY_HOME) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.featured[action.index].itinerary.is_liked = true;
    newState.featured[action.index].itinerary.like++;
    return newState;
  }

  if (action.type === actionTypes.UNLIKE_ITINERARY_HOME_BY_ID) {
    const newState = JSON.parse(JSON.stringify(state));
    for (let i = 0; i < newState.featured.length; i++) {
      if (newState.featured[i].itinerary.id === action.id) {
        newState.featured[i].itinerary.is_liked = false;
        newState.featured[i].itinerary.like--;
        break;
      }
    }
    return newState;
  }

  if (action.type === actionTypes.LIKE_ITINERARY_HOME_BY_ID) {
    const newState = JSON.parse(JSON.stringify(state));
    for (let i = 0; i < newState.featured.length; i++) {
      if (newState.featured[i].itinerary.id === action.id) {
        newState.featured[i].itinerary.is_liked = true;
        newState.featured[i].itinerary.like++;
      }
    }
    return newState;
  }
  return state;
};



function update_data(datagram, id, currentArray) {
  for (let i = 0; i < currentArray.length; i++) {
    if (currentArray[i].id !== undefined && currentArray[i].id === id) {
      currentArray[i] = datagram;
      break;
    }
    if (
      currentArray[i].user_id !== undefined &&
      currentArray[i].user_id === id
    ) {
      currentArray[i] = datagram;
      break;
    }
    if (currentArray[i].site !== undefined && currentArray[i].site.id === id) {
      currentArray[i] = datagram;
      break;
    }
  }
}

function delete_data(id, currentArray) {
  let deleteIndex = -1;
  for (let i = 0; i < currentArray.length; i++) {
    if (currentArray[i].id !== undefined && currentArray[i].id === id) {
      deleteIndex = i;
      break;
    }
    if (
      currentArray[i].user_id !== undefined &&
      currentArray[i].user_id === id
    ) {
      deleteIndex = i;
      break;
    }
    if (currentArray[i].site !== undefined && currentArray[i].site.id === id) {
      deleteIndex = i;
      break;
    }
    if (
      currentArray[i].itinerary !== undefined &&
      currentArray[i].itinerary.id === id
    ) {
      deleteIndex = i;
      break;
    }
  }
  if (deleteIndex != -1) {
    currentArray.splice(deleteIndex, 1);
  }
}
