import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  restore_city,
  update_dashboard_data,
  create_dashboard_data,
  delete_dashboard_data
} from "../../actions/api/content";
import clsx from "clsx";
import Image from "material-ui-image";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import PublishIcon from "@material-ui/icons/Publish";
const useStyles = makeStyles(theme => ({
  mainpaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));
function City(props) {
  useEffect(() => {
    props.handleRestoreCity();
  }, []);
  const classes = useStyles();
  const fixedHeightPaper = classes.mainpaper;
  const cities = props.cities;
  const [tempFile, setTempFile] = React.useState();
  const [id, setId] = React.useState();
  const [city_name, setCityName] = React.useState();
  const [country_name, setCountryName] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleSubmitEdit = () => {
    const datagram = {
      id: id,
      city_name: city_name,
      country_name: country_name
    };
    props.handleUpdateCityDispatch(datagram, tempFile, id, props.token);
    setEditOpen(!editOpen);
    setId("");
    setCityName("");
    setCountryName("");
  };
  const handleCreateCity = () => {
    const datagram = {
      id: id,
      city_name: city_name,
      country_name: country_name
    };
    props.handleCreateCityDispatch(datagram, tempFile, props.token);
    setTempFile("");
    setCreateOpen(!createOpen);
    setId("");
    setCityName("");
    setCountryName("");
  };
  const onFileChange = event => {
    return setTempFile(event.target.files[0]);
  };
  return (
    <Paper className={fixedHeightPaper}>
      <React.Fragment>
        <Title>
          Cities           
          <Button color="primary" onClick={() => setCreateOpen(!createOpen)}>
            <AddIcon />
          </Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={createOpen}
            onClose={() => setCreateOpen(!createOpen)}
          >
            <DialogTitle>Creating City</DialogTitle>
            <DialogContent>
              <TextField
                id="outlined-helperText"
                label="City Name"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setCityName(e.target.value)}
              />
              <TextField
                id="outlined-helperText"
                label="Country Name"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={e => setCountryName(e.target.value)}
              />
              <div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button component="span">
                    Upload Photo
                    <PublishIcon />
                  </Button>
                </label>
                {tempFile === undefined || tempFile === "" ? (
                  <p>No file change</p>
                ) : (
                  <p>Photo Uploaded </p>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCreateOpen(!createOpen);
                  setId("");
                  setCityName("");
                  setCountryName("");
                }}
                color="primary"
              >
                Cancel               
              </Button>
              <Button onClick={() => handleCreateCity()} color="primary">
                Ok               
              </Button>
            </DialogActions>
          </Dialog>
        </Title>
        <Table class="dashboard-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities === undefined ? (
              <div />
            ) : (
              cities.map(city => (
                <TableRow key={city.id}>
                  <TableCell>{city.id}</TableCell>
                  <TableCell>{city.country_name}</TableCell>
                  <TableCell>{city.city_name}</TableCell>
                  <TableCell>
                    <Image
                      src={city.photo}
                      id="city-image"
                      aspectRatio={16 / 9}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      onClick={() => {
                        setEditOpen(!editOpen);
                        setId(city.id);
                        setCityName(city.city_name);
                        setCountryName(city.country_name);
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      open={editOpen}
                      onClose={() => setEditOpen(!editOpen)}
                    >
                      <DialogTitle>Editing City {city_name}</DialogTitle>
                      <DialogContent>
                        <TextField
                          id="outlined-disabled"
                          disabled
                          label="City ID"
                          defaultValue={id}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="City Name"
                          defaultValue={city_name}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setCityName(e.target.value)}
                        />
                        <TextField
                          id="outlined-helperText"
                          label="Country Name"
                          defaultValue={country_name}
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          onChange={e => setCountryName(e.target.value)}
                        />
                        <div>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={onFileChange}
                          />
                          <label htmlFor="raised-button-file">
                            <Button component="span">
                              Change Photo 
                              <PublishIcon />
                            </Button>
                          </label>
                          {tempFile === undefined || tempFile === "" ? (
                            <p>No file change</p>
                          ) : (
                            <p>Photo Uploaded </p>
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setEditOpen(!editOpen);
                            setId("");
                            setCityName("");
                            setCountryName("");
                          }}
                          color="primary"
                        >
                          Cancel                             
                        </Button>
                        <Button
                          onClick={() => handleSubmitEdit()}
                          color="primary"
                        >
                          Ok                             
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button
                      color="primary"
                      onClick={() => {
                        setDeleteOpen(true);
                        setId(city.id);
                        setCityName(city.city_name);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Dialog
                      open={deleteOpen}
                      onClose={() => setDeleteOpen(!deleteOpen)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete City"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to delete {city_name}?
                                                      
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            props.handleDeleteCity(id, props.token);
                            setId("");
                            setCityName("");
                          }}
                          color="primary"
                        >
                          Delete                             
                        </Button>
                        <Button
                          onClick={() => {
                            setDeleteOpen(!deleteOpen);
                            setId("");
                            setCityName("");
                          }}
                          color="primary"
                          autoFocus
                        >
                          Cancel                             
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    </Paper>
  );
}
//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    cities: state.admin.cities,
    token: state.auth.access_token
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleRestoreCity() {
      dispatch(restore_city());
    },
    handleCreateCityDispatch(datagram, tempFile, token) {
      dispatch(create_dashboard_data(datagram, tempFile, "city", token));
    },
    handleUpdateCityDispatch(datagram, tempFile, id, token) {
      dispatch(update_dashboard_data(datagram, tempFile, id, "city", token));
    },
    handleDeleteCity(id, token) {
      dispatch(delete_dashboard_data(id, "city", token));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(City);
