import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';
import thunk from 'redux-thunk';

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
  compose);

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(thunk),
  )
);


export { reducer, actionCreators, actionTypes };
export default store;
