import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
// import { AiOutlineLogin, AiOutlineLogout } from 'react-icons/ai';
import FaceIcon from "@material-ui/icons/Face";
import BuildIcon from "@material-ui/icons/Build";
import Image from "material-ui-image";

import { Route, Switch, Link } from "react-router-dom";
import { connectWithStore } from "./utils";

import { Provider } from "react-redux";
import store from "./store";
import { logout } from "./actions/api/auth";
import ItineraryContainer from "./components/itinerary/IteneraryContainer";
import LoginPage from "./components/login";
import RegisterPage from "./components/register";
import Dashboard from "./components/admin/Dashboard";
import Home from "./components/Home";
import AppManager from "./components/appmanager";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import purple from "@material-ui/core/colors/purple";
import UserProfile from "./components/user_profile/UserProfile";
import AddCity from "./components/itinerary/AddCity";
import Itinerary from "./components/itinerary/Itinerary";
import DayTrip from "./components/itinerary/DayTrip";
import ItineraryDetailView from "./components/itinerary/ItineraryDetailView";
// import PlaceDetail from "./components/itinerary/PlaceDetail";
// import TripDetail from "./components/itinerary/tripDetail";

import "./App.css";
import PlaceDetail from "./components/itinerary/PlaceDetail";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentColor: "black",
      anchorEl: null
    };
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  handleMenuClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    document.title = "TripHub";
  }

  render() {
    return (
      <Router>
        <Provider store={store}>
          <AppManager>
            <div className="top-bar-container ">
              <Link className="top-bar" to="/">
                <Button variant="contained">
                  {" "}
                  <HomeIcon />
                </Button>
              </Link>
              {this.props.logged_in ? (
                <div className="top-bar">
                  <Button variant="contained" onClick={this.handleMenuClick}>
                    <FaceIcon />
                  </Button>
                  <Menu
                    zIndex="100000000"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem onClick={this.handleMenuClose}>
                      <Link to="/profile" onClick={this.handleClose}>
                        Profile
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={this.props.handleLogout}>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                  <Link to="/register" className="top-bar">
                    <Button variant="contained">
                      <FaceIcon />
                    </Button>
                  </Link>
                )}
              {this.props.logged_in && this.props.is_staff ? (
                <Link to="/admin" className="top-bar">
                  <Button variant="contained">
                    <BuildIcon />
                  </Button>
                </Link>
              ) : (
                  <div />
                )}
            </div>
            <Switch>
              {/* <Route path="/tripDetail" component={TripDetail} /> */}
              <Route path="/DayTrip" component={DayTrip} />
              <Route path="/adjustCity" component={Itinerary} />
              <Route path="/addCity" component={AddCity} />
              <Route path="/profile" component={UserProfile} />
              <Route path="/login">
                <LoginPage
                  {...this.props}
                  handleMenuClose={this.handleMenuClose}
                />
              </Route>
              <Route path="/register">
                <RegisterPage handleMenuClose={this.handleMenuClose} />
              </Route>
              <Route path="/admin" component={Dashboard} />
              <Route path="/itinerary/detail" component={ItineraryDetailView} />
              <Route path="/site/detail" component={PlaceDetail} />
              <Route path="/itinerary" component={ItineraryContainer} />
              <Route path="/" component={Home} />
            </Switch>
          </AppManager>
          {/* TODO: sizing */}
        </Provider>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    logged_in: state.auth.access_token !== undefined,
    is_staff: state.auth.is_staff === "true"
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogout() {
      dispatch(logout());
    }
  };
}

export default connectWithStore(
  store,
  App,
  mapStateToProps,
  mapDispatchToProps
);
