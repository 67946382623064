//Edit firstname, lastname, add profile pic
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { update_user_profile } from "../../actions/api/content";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: '0 auto',
        marginTop: 25,
        width: 200,
        textAlign: 'center',
    },
    paper: {
        margin: 20,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 420,
    },
    title: {
        textAlign: 'center',
        fontSize: 30,
    },
}));
function EditProfile(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [first_name, setFirstName] = React.useState(props.first_name);
    const [last_name, setLastName] = React.useState(props.last_name);
    const [email, setEmail] = React.useState(props.email);
    const [tempFile, setTempFile] = React.useState();
    const onFileChange = (event) => {
        return setTempFile(event.target.files[0]);
    }

    const handleSubmitEdit = () => {
        const datagram = {
            user_id: props.user_id,
            last_name: last_name,
            first_name: first_name,
            email: email,
            username: props.username,
            password: '',
            is_staff: '',
        }
        props.handlUpdateUserInfo(props.user_id, datagram, tempFile, props.token);
        setTempFile('');
    }

    return (
        <div>
            <Typography component="h1" variant="h6" color="primary" gutterBottom className={classes.title}>
                Edit Profile
            </Typography>
            <Paper className={fixedHeightPaper}>
                <TextField
                    id="outlined-helperText"
                    label="Last Name"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setLastName(e.target.value)}
                    defaultValue={props.last_name}
                />
                <TextField
                    id="outlined-helperText"
                    label="First Name"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setFirstName(e.target.value)}
                    defaultValue={props.first_name}
                />
                <TextField
                    id="outlined-helperText"
                    label="Email"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={props.email}
                />
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={onFileChange}
                />
                <label className="upload-user-profile-pic" htmlFor="raised-button-file">
                    <Button component="span">
                        Change Profile Picture<PublishIcon />
                    </Button>
                </label>
                {
                    tempFile === undefined || tempFile === "" ?
                        <p className="upload-user-profile-pic">No file change</p> : <p className="upload-user-profile-pic">Photo Uploaded </p>
                }
                <Button color="primary" onClick={() => handleSubmitEdit()}>Submit</Button>
                <Button onClick={() => props.setEditStatus(false)}>Cancel</Button>
            </Paper>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        username: state.auth.username,
        token: state.auth.access_token,
        logged_in: state.auth.access_token !== undefined,
        user_id: state.auth.user_id,
        last_name: state.user.last_name,
        first_name: state.user.first_name,
        email: state.user.email,
        profile_pic: state.user.profile_pic,
        is_staff: state.auth.is_staff,
    };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
    return {
        handlUpdateUserInfo(userId, userInfo, tempFile, token) {
            dispatch(update_user_profile(userId, userInfo, tempFile, token));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
