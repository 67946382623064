import * as actionTypes from './actionTypes';

/**
 * Itinerary
 */
export const initItinerary = (datagram) => ({
    type: actionTypes.INIT_ITINERARY,
    datagram: datagram,
});

// export const finInitItinerary = () => ({
//     type: actionTypes.FIN_INIT_ITINERARY,
// });

// export const resetFinInitItinerary = () => ({
//     type: actionTypes.RESET_FIN_INIT_ITINERARY,
// });

export const clearInitItinerary = () => ({
    type: actionTypes.CLEAR_INIT_ITINERARY,
});

export const createItinerary = (datagram) => ({
    type: actionTypes.CREATE_ITINERARY,
    datagram: datagram,
});

export const updateItinerary = (datagram) => ({
    type: actionTypes.UPDATE_ITINERARY,
    datagram: datagram,
});

export const deleteItinerary = (id) => ({
    type: actionTypes.DELETE_ITINERARY,
    id: id
});

export const setCurrentItineraryIndex = (index) => ({
    type: actionTypes.SET_CURRENT_ITINIERARY_INDEX,
    index: index,
});

export const restoreItinFailure = () => ({
    type: actionTypes.RESTORE_ITIN_FAILURE,
})

export const initTrendingItinerary = (datagram) => ({
    type: actionTypes.INIT_TRENDING_ITINERARY,
    datagram: datagram,
})

export const initSingleItinerary = (datagram) => ({
    type: actionTypes.INIT_SINGLE_ITINERARY,
    datagram: datagram,
})

export const unlikeItinerary = () => ({
    type: actionTypes.UNLIKE_ITINERARY,
})

export const likeItinerary = () => ({
    type: actionTypes.LIKE_ITINERARY
})

export const unlikeTrendingItinerary = (index) => ({
    type: actionTypes.UNLIKE_TRENDING_ITINERARY,
    index: index
})

export const likeTrendingItinerary = (index) => ({
    type: actionTypes.LIKE_TRENDING_ITINERARY,
    index: index
})

export const unlikeTrendingItineraryById = (id) => ({
    type: actionTypes.UNLIKE_TRENDING_ITINERARY_BY_ID,
    id: id
})

export const likeTrendingItineraryById = (id) => ({
    type: actionTypes.LIKE_TRENDING_ITINERARY_BY_ID,
    id: id
})

/**
 * Trip
 */
export const createTrip = (datagram) => ({
    type: actionTypes.CREATE_TRIP,
    datagram: datagram,
});

export const updateTrip = (datagram) => ({
    type: actionTypes.UPDATE_TRIP,
    datagram: datagram,
});

export const updateDayTripSite = (datagram) => ({
    type: actionTypes.UPDATE_DAY_TRIP_SITE,
    datagram: datagram
});

export const deleteTrip = (id) => ({
    type: actionTypes.DELETE_TRIP,
    id: id
});

export const deleteTripSite = (tripSiteIndex) => ({
    type: actionTypes.DELETE_TRIP_SITE,
    tripSiteIndex: tripSiteIndex,
});

export const initTrip = (datagram) => ({
    type: actionTypes.INIT_TRIP,
    datagram: datagram,
});

export const clearInitTrip = () => ({
    type: actionTypes.CLEAR_INIT_TRIP,
});

export const initSingleTrip = (datagram) => ({
    type: actionTypes.INIT_SINGLE_TRIP,
    datagram: datagram,
});

export const initFavoriteItinerary = (datagram) => ({
    type: actionTypes.INIT_FAVORITE_ITINERARY,
    datagram: datagram,
})
export const clearInitSingleTrip = () => ({
    type: actionTypes.CLEAR_INIT_SINGLE_TRIP,
});
/**
 * Site
 */
export const createTripSite = (datagram) => ({
    type: actionTypes.CREATE_TRIP_SITE,
    datagram: datagram
});

export const clearInitSite = () => ({
    type: actionTypes.CLEAR_INIT_SITE,
});

export const filterSite = (datagram) => ({
    type: actionTypes.FILTER_SITE,
    datagram: datagram
});

export const initSiteDetail = (datagram) => ({
    type: actionTypes.INIT_SITE_DETAIL,
    datagram: datagram
});


/**
 * City
 */
export const initCityList = () => ({
    type: actionTypes.INIT_CITY_LIST,
});

export const addCityList = (tripId, city) => ({
    type: actionTypes.ADD_CITY_LIST,
    tripId: tripId,
    city: city,
});

export const clearInitCityList = () => ({
    type: actionTypes.CLEAR_INIT_CITY_LIST,
});

export const clearTempCitylist = () => ({
    type: actionTypes.CLEAR_TEMPCITYLIST,
});