import * as actionTypes from './actionTypes';
const defaultState = {
    itineraries: [], // itinerary info except trip info
    trips: [],// itinerary id, numDays, site[]
    // itinRestoreFin: false,
    // tripRestoreFin: false,
    sites: [], // filtered sites
    currentItinIndex: 0,
    retrieveItinFailure: false,
    viewSiteDetail: '',// one time one site
    trendingItineraries: '',
    singleItinerary: {},
    singleTrip: {},
    tempCitylist: [],
    favoriteItineraries: '',
}

export default (state = defaultState, action) => {
    /**
     * Itinerary
     */
    if (action.type === actionTypes.INIT_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.itineraries = action.datagram;
        newState.currentItinIndex = newState.itineraries.length - 1;
        console.log("reducer init itin datagram" + newState.itineraries.length);
        return newState;
    }

    // if (action.type === actionTypes.FIN_INIT_ITINERARY) {
    //     const newState = JSON.parse(JSON.stringify(state));
    //     newState.itinRestoreFin = true;
    //     return newState;
    // }

    // if (action.type === actionTypes.RESET_FIN_INIT_ITINERARY) {
    //     const newState = JSON.parse(JSON.stringify(state));
    //     newState.itinRestoreFin = false;
    //     return newState;
    // }

    if (action.type === actionTypes.CLEAR_INIT_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.itineraries = [];
        return newState;
    }

    if (action.type === actionTypes.RESTORE_ITIN_FAILURE) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.retrieveItinFailure = !newState.retrieveItinFailure;
        return newState;
    }

    if (action.type === actionTypes.SET_CURRENT_ITINIERARY_INDEX) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.currentItinIndex = action.index;
        return newState;
    }

    if (action.type === actionTypes.CREATE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.itineraries.push(action.datagram);
        newState.currentItinIndex = newState.itineraries.length - 1;
        console.log("currentItinIndex" + newState.currentItinIndex);
        return newState;
    }

    if (action.type === actionTypes.UPDATE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        update_data(action.datagram, action.datagram.id, newState.itineraries);
        return newState;
    }

    if (action.type === actionTypes.DELETE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        delete_data(action.id, newState.itineraries);
        return newState;
    }

    if (action.type === actionTypes.INIT_TRENDING_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.trendingItineraries = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.INIT_SINGLE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.singleItinerary = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.INIT_FAVORITE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.favoriteItineraries = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.UNLIKE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.singleItinerary.is_liked = false;
        newState.singleItinerary.like--;
        return newState;
    }

    if (action.type === actionTypes.LIKE_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.singleItinerary.is_liked = true;
        newState.singleItinerary.like++;
        return newState;
    }

    if (action.type === actionTypes.UNLIKE_TRENDING_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.trendingItineraries[action.index].is_liked = false;
        newState.trendingItineraries[action.index].like--;
        return newState;
    }

    if (action.type === actionTypes.LIKE_TRENDING_ITINERARY) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.trendingItineraries[action.index].is_liked = true;
        newState.trendingItineraries[action.index].like++;
        return newState;
    }

    if (action.type === actionTypes.UNLIKE_TRENDING_ITINERARY_BY_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        for (let i = 0; i < newState.trendingItineraries.length; i++) {
            if (newState.trendingItineraries[i].id === action.id) {
                newState.trendingItineraries[i].is_liked = false;
                newState.trendingItineraries[i].like--;
                break;
            }
        }
        return newState;
    }

    if (action.type === actionTypes.LIKE_TRENDING_ITINERARY_BY_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        for (let i = 0; i < newState.trendingItineraries.length; i++) {
            if (newState.trendingItineraries[i].id === action.id) {
                newState.trendingItineraries[i].is_liked = true;
                newState.trendingItineraries[i].like++;
                break;
            }
        }
        return newState;
    }

    /**
     * Trip
     */
    if (action.type === actionTypes.INIT_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.trips = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.CLEAR_INIT_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.trips = [];
        return newState;
    }

    if (action.type === actionTypes.CREATE_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        let newData = action.datagram;
        newState.trips.push(newData);
        return newState;
    }

    if (action.type === actionTypes.UPDATE_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        update_data(action.datagram, action.datagram.id, newState.itineraries);
        update_data(action.datagram, action.datagram.id, newState.trips);
        return newState;
    }

    if (action.type === actionTypes.UPDATE_DAY_TRIP_SITE) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.singleTrip = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.DELETE_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        delete_data(action.id, newState.trips);
        return newState;
    }

    if (action.type === actionTypes.DELETE_TRIP_SITE) {
        const newState = JSON.parse(JSON.stringify(state));
        console.log("deleting " + action.tripSiteIndex);
        newState.singleTrip.splice(action.tripSiteIndex, 1);
        return newState;
    }

    if (action.type === actionTypes.INIT_SINGLE_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        if (action.datagram !== undefined) {
            newState.singleTrip = action.datagram;
        } else {
            newState.singleTrip = {};
        }
        return newState;
    }

    if (action.type === actionTypes.CLEAR_INIT_SINGLE_TRIP) {
        const newState = JSON.parse(JSON.stringify(state));
        if (action.datagram !== undefined) {
            newState.singleTrip = action.datagram;
        } else {
            newState.singleTrip = {};
        }
        return newState;
    }
    /**
     * City
     */
    if (action.type === actionTypes.CLEAR_INIT_CITY_LIST) {
        const newState = JSON.parse(JSON.stringify(state));
        const trips = newState.trips;
        for (let i = 0; i < trips.length; i++) {
            trips[i].citylist = [];
        }
        return newState;
    }

    if (action.type === actionTypes.INIT_CITY_LIST) {
        const newState = JSON.parse(JSON.stringify(state));
        const trips = newState.trips;
        for (let i = 0; i < trips.length; i++) {
            trips[i].citylist = findCitylist(trips[i]);
        }
        return newState;
    }

    if (action.type === actionTypes.ADD_CITY_LIST) {
        const newState = JSON.parse(JSON.stringify(state));
        if (JSON.stringify(newState.tempCitylist) === "{}" || newState.tempCitylist === undefined) {
            newState.tempCitylist = [];
            newState.tempCitylist.push(action.city);
        } else {
            newState.tempCitylist.push(action.city);
        }
        console.log("in???" + JSON.stringify(newState.singleTrip));
        console.log("in???" + JSON.stringify(newState.tempCitylist));
        return newState;
    }

    if (action.type === actionTypes.CLEAR_TEMPCITYLIST) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.tempCitylist = [];
        return newState;
    }

    /**
     * Site
     */
    if (action.type === actionTypes.CLEAR_INIT_SITE) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.sites = [];
        return newState;
    }

    if (action.type === actionTypes.CREATE_TRIP_SITE) {
        const newState = JSON.parse(JSON.stringify(state));
        console.log(JSON.stringify("giest" + newState.singleTrip));
        if (newState.singleTrip == undefined || JSON.stringify(newState.singleTrip) === "{}") {
            newState.singleTrip = [];
            newState.singleTrip.push(action.datagram);
        } else {
            newState.singleTrip.push(action.datagram);
        }
        console.log(JSON.stringify("sec" + newState.singleTrip));
        return newState;
    }

    if (action.type === actionTypes.FILTER_SITE) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.sites = action.datagram;
        return newState;
    }

    if (action.type === actionTypes.INIT_SITE_DETAIL) {
        const newState = JSON.parse(JSON.stringify(state));
        console.log("action datagram" + action.datagram);
        newState.viewSiteDetail = action.datagram;
        return newState;
    }

    return state;
}

function update_data(datagram, id, currentArray) {
    for (let i = 0; i < currentArray.length; i++) {
        if (currentArray[i].id === id) {
            currentArray[i] = datagram;
        }
    }
}

function delete_data(id, currentArray) {
    let deleteIndex = -1;
    for (let i = 0; i < currentArray.length; i++) {
        if (currentArray[i].id === id) {
            deleteIndex = i;
        }
    }
    if (deleteIndex != -1) {
        currentArray.splice(deleteIndex, 1);
    }
}

function findCitylist(trip) {
    let citylist = [];
    let cityIdArr = [];
    for (let i = 0; i < trip.sites.length; i++) {
        if (!cityIdArr.includes(trip.sites[i].site.city.id)) {
            citylist.push(trip.sites[i].site.city);
            cityIdArr.push(trip.sites[i].site.city.id);
        }
    }
    console.log("citylist in reducer" + JSON.stringify(citylist));
    return citylist;
}
