import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import hotel from "../../static/hotel.png";
import attraction from "../../static/attraction.png";
import store from "../../static/store.png";
import { useEffect } from "react";
import { restore_site_detail } from "../../actions/api/content";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import { restore_user_profile } from "../../actions/api/content";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  root: {
    width: "100%",
    padding: "30px"
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    width: "100%",
    height: "100%"
  },
  media: {
    height: 500,
    width: 1000
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  toolbar: {
    backgroundImage: `url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80")`,
    height: 200
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TripHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function PlaceDetail(props) {
  const classes = useStyles();
  let history = useHistory();
  useEffect(() => {
    props.handleGetUserInfo(props.user_id);
  }, []);

  let currentSiteType = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.lastIndexOf("_")
  );
  let currentSiteId = window.location.href.substring(
    window.location.href.lastIndexOf("_") + 1
  );

  useEffect(() => {
    console.log("current Site id" + currentSiteId + " " + currentSiteType);

    props.handleViewSiteDetail(currentSiteId, currentSiteType);
    console.log(props.viewSiteDetail);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <Avatar
            alt="User Avatar"
            className={classes.bigAvatar}
            src={props.profile_pic}
          />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Typography variant="h6" color="inherit" noWrap>
            Hi, {props.username}
          </Typography>
        </Toolbar>
      </AppBar>
      {props.viewSiteDetail === "" ? (
        <div />
      ) : (
          <main>
            <div className={classes.heroContent}>
              <Container maxWidth="sm" className="title-container">
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  {props.viewSiteDetail.site.name}
                </Typography>
                <div className="detail-site-icon">
                  {props.viewSiteDetail.site.site_category === "Attraction" ? (
                    <img src={attraction} />
                  ) : props.viewSiteDetail.site.site_category === "Hotel" ? (
                    <img src={hotel} />
                  ) : (
                        <img src={store} />
                      )}
                </div>
                <Grid container spacing={4} justify="center">
                  <Grid item>
                    <Typography
                      variant="h7"
                      align="center"
                      color="textSecondary"
                      paragraph
                    >
                      {props.viewSiteDetail.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </div>
            <Grid container justify="center">
              <Grid item>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={props.viewSiteDetail.site.photo}
                      title="detail photo"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            <Container className={classes.heroContent} maxWidth="md">
              <Paper className={classes.root}>
                <Typography component="p" display="block" paragraph="true">
                  @ {props.viewSiteDetail.site.city.city_name},{" "}
                  {props.viewSiteDetail.site.city.country_name}
                </Typography>
                <Typography
                  variant="h6"
                  component="h5"
                  display="block"
                  paragraph="true"
                >
                  Description: {props.viewSiteDetail.site.description}
                </Typography>
                <Typography
                  variant="h6"
                  component="h5"
                  display="block"
                  gutterBottom="true"
                >
                  Category: {props.viewSiteDetail.category}
                </Typography>
              </Paper>
            </Container>
            <Container className={classes.cardGrid} maxWidth="md">
              <Grid container spacing={2} justify="center"></Grid>
            </Container>
            <div className={classes.heroButtons}>
              <Grid container spacing={8} justify="center">
                <Grid item>
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    Back
                </Button>
                </Grid>
              </Grid>
            </div>
          </main>
        )}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Find us @
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          <TwitterIcon />
          <FacebookIcon />
        </Typography>
        <Copyright />
      </footer>
    </React.Fragment>
  );
}

//react - redux main API 1: map store.state to props
const mapStateToProps = state => {
  return {
    viewSiteDetail: state.itinerary.viewSiteDetail,
    username: state.auth.username,
    token: state.auth.access_token,
    logged_in: state.auth.access_token !== undefined,
    user_id: state.auth.user_id,
    profile_pic: state.user.profile_pic,
    itineraries: state.itinerary.itineraries
  };
};
// react-redux main API 2: map store.dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    handleViewSiteDetail(id, type) {
      dispatch(restore_site_detail(id, type));
    },
    handleGetUserInfo(user_id) {
      dispatch(restore_user_profile(user_id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDetail);
