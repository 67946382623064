import axios from "axios";
import { actionCreators as adminActionCreators } from "../../components/admin/store";
import { actionCreators as userActionCreators } from "../../components/user_profile/store";
import { actionCreators as itineraryActionCreators } from "../../components/itinerary/store";
import { refresh_token } from "./auth";

const baseURL = 'http://www.mytriphub.net/api/';

const api_request = axios.create({
  baseURL: baseURL
});


/**
 * General
 */
export function update_dashboard_data(newData, tempFile, id, dataType, token) {
  console.log(JSON.stringify(newData) + "$$$");
  let bodyFormData = new FormData();

  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }

  if (
    dataType !== "attraction" &&
    dataType !== "restaurant" &&
    dataType !== "hotel"
  ) {
    if (tempFile != "" && tempFile != undefined) {
      bodyFormData.append("photo", tempFile);
    }
  } else {
    if (tempFile != "" && tempFile != undefined) {
      bodyFormData.append("site.photo", tempFile);
    }
  }
  return async dispatch => {
    const url =
      baseURL +
      dataType +
      `/${id}/`;
    return axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        console.log(id);
        dispatch(
          adminActionCreators.updateDashboardData(response.data, id, dataType)
        );
        console.log(response.data);
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function create_dashboard_data(newData, tempFile, dataType, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }

  if (
    dataType !== "attraction" &&
    dataType !== "restaurant" &&
    dataType !== "hotel"
  ) {
    if (tempFile != "" && tempFile != undefined) {
      bodyFormData.append("photo", tempFile);
    }
  } else {
    if (tempFile != "" && tempFile != undefined) {
      bodyFormData.append("site.photo", tempFile);
    }
  }
  return async dispatch => {
    const url =
      baseURL + dataType + "/";
    return axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(
          adminActionCreators.createDashboardData(response.data, dataType)
        );
        console.log(response.data);
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function delete_dashboard_data(id, dataType, token) {
  return async dispatch => {
    dispatch(adminActionCreators.deleteDashboardData(id, dataType));
    const url =
      baseURL +
      dataType +
      `/${id}/`;
    return axios({
      method: "DELETE",
      url: url,
      headers: {
        Authorization: "Token " + token
      }
    }).catch(error => {
      refresh_token(token);
      alert(JSON.stringify(error.response.data));
      console.log(error);
    });
  };
}

/**
 * City
 */
export function restore_city() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitCities());
    return api_request
      .get("city/")
      .then(response => {
        const cities = response.data;
        for (let i = 0; i < cities.length; i++) {
          dispatch(adminActionCreators.initCities(cities[i]));
        }
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * Site
 */

export function restore_site_by_city(cityId, siteType) {
  // type includes: attraction, hotel, restaurant
  return async dispatch => {
    dispatch(itineraryActionCreators.clearInitSite());
    return axios({
      method: "GET",
      url: baseURL + `${siteType}/?city=${cityId}`
    })
      .then(response => {
        dispatch(itineraryActionCreators.filterSite(response.data));
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function restore_site_detail(id, type) {
  // for user
  return async dispatch => {
    if (type === "Restaurant") {
      return api_request
        .get(`restaurant/${id}`)
        .then(response => {
          dispatch(itineraryActionCreators.initSiteDetail(response.data));
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    } else if (type === "Hotel") {
      return api_request
        .get(`hotel/${id}`)
        .then(response => {
          dispatch(itineraryActionCreators.initSiteDetail(response.data));
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    } else if (type === "Attraction") {
      return api_request
        .get(`attraction/${id}`)
        .then(response => {
          dispatch(itineraryActionCreators.initSiteDetail(response.data));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
}

/**
 * Attraction
 */
export function restore_attraction() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitAttractions());
    return api_request
      .get("attraction/")
      .then(response => {
        const attractions = response.data;
        for (let i = 0; i < attractions.length; i++) {
          dispatch(adminActionCreators.initAttractions(attractions[i]));
        }
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * Restaurant
 */
export function restore_restaurant() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitRestaurants());
    return api_request
      .get("restaurant/")
      .then(response => {
        const restaurants = response.data;
        for (let i = 0; i < restaurants.length; i++) {
          dispatch(adminActionCreators.initRestaurants(restaurants[i]));
        }
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * Hotel
 */
export function restore_hotel() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitHotels());
    return api_request
      .get("hotel/")
      .then(response => {
        const hotels = response.data;
        for (let i = 0; i < hotels.length; i++) {
          dispatch(adminActionCreators.initHotels(hotels[i]));
        }
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * Featured
 */
export function restore_featured(token) {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitFeatured());
    if (token === undefined || token === "") {
      console.log("testing none token");
      return api_request
        .get("featured/")
        .then(response => {
          const featured = response.data;
          for (let i = 0; i < featured.length; i++) {
            dispatch(adminActionCreators.initFeatured(featured[i]));
          }
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    } else {
      return axios({
        method: "GET",
        url: baseURL,
        headers: {
          Authorization: "Token " + token
        },
      })
        .then(response => {
          const featured = response.data;
          for (let i = 0; i < featured.length; i++) {
            dispatch(adminActionCreators.initFeatured(featured[i]));
          }
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    }
  };
}

/**
 * Highlight
 */
export function restore_highlight() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitHighlights());
    return api_request
      .get("highlight/")
      .then(response => {
        const highlights = response.data;
        dispatch(adminActionCreators.initHighlights(highlights));
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * User
 */
export function restore_user() {
  // for dashboard
  return async dispatch => {
    dispatch(adminActionCreators.clearInitUsers());
    return api_request
      .get("user/")
      .then(response => {
        const users = response.data;
        for (let i = 0; i < users.length; i++) {
          dispatch(adminActionCreators.initUsers(users[i]));
        }
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function restore_user_profile(user_id) {
  return async dispatch => {
    dispatch(userActionCreators.clearInitUserProfile());
    return api_request
      .get("user/" + `${user_id}/`)
      .then(response => {
        const usersInfo = response.data;
        dispatch(userActionCreators.initUserProfile(usersInfo));
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function update_user_profile(user_id, user_info, tempFile, token) {
  let bodyFormData = new FormData();
  for (let key in user_info) {
    bodyFormData.append(key, user_info[key]);
  }
  if (tempFile != "" && tempFile != undefined) {
    bodyFormData.append("profile_pic", tempFile);
  }
  return async dispatch => {
    const url = baseURL + `user/` + `${user_id}/`;
    return axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(userActionCreators.updateUserProfile(response.data));
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        refresh_token(token);
        console.log(error);
      });
  };
}

/**
 * Itinerary
 */
export function restore_user_itinerary(token) {
  if (token === undefined) {
    return async dispatch => {
      const url =
        baseURL + `itinerary/`;
      dispatch(itineraryActionCreators.clearInitItinerary());
      return axios({
        method: "GET",
        url: url,
      })
        .then(response => {
          dispatch(itineraryActionCreators.initItinerary(response.data));
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  } else {
    return async dispatch => {
      const url =
        baseURL + `itinerary/`;
      dispatch(itineraryActionCreators.clearInitItinerary());
      return axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Token " + token
        }
      })
        .then(response => {
          dispatch(itineraryActionCreators.initItinerary(response.data));
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  }
}

export function restore_public_itinerary(token) {
  return async dispatch => {
    const url =
      baseURL + `itinerary/?allPublic=true`;
    dispatch(itineraryActionCreators.clearInitItinerary());
    return axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(response => {
        dispatch(itineraryActionCreators.initItinerary(response.data));
      })
      .catch(error => {
        dispatch(itineraryActionCreators.restoreItinFailure());
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function restore_trending_itinerary(token) {
  if (token === undefined) {
    return async dispatch => {
      return axios({
        method: "GET",
        url:
          baseURL + `itinerary/?allPublic=true&sortBy=view&limit=12`
      })
        .then(response => {
          dispatch(itineraryActionCreators.initTrendingItinerary(response.data));
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  } else {
    return async dispatch => {
      return axios({
        method: "GET",
        url: baseURL + `itinerary/?allPublic=true&sortBy=view&limit=12`,
        headers: {
          Authorization: "Token " + token
        },
      }).then(response => {
        dispatch(itineraryActionCreators.initTrendingItinerary(response.data));
      })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  }
}

export function restore_single_itinerary(id, token) {
  if (token === undefined) {
    return async dispatch => {
      return axios({
        method: "GET",
        url: baseURL + `itinerary/${id}`,
      })
        .then(response => {
          dispatch(itineraryActionCreators.initSingleItinerary(response.data));
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  } else {
    return async dispatch => {
      return axios({
        method: "GET",
        url: baseURL + `itinerary/${id}`,
        headers: {
          Authorization: "Token " + token
        },
      })
        .then(response => {
          dispatch(itineraryActionCreators.initSingleItinerary(response.data));
        })
        .catch(error => {
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  }

}

export function restore_favorite_itinerary(user_id, token) {
  if (token === undefined) {
    return async dispatch => {
      return axios({
        method: "GET",
        url: baseURL + `like/?user=${user_id}`,
      })
        .then(response => {
          dispatch(itineraryActionCreators.initFavoriteItinerary(response.data));
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  } else {
    return async dispatch => {
      return axios({
        method: "GET",
        url: baseURL + `like/?user=${user_id}`,
        headers: {
          Authorization: "Token " + token
        }
      })
        .then(response => {
          dispatch(itineraryActionCreators.initFavoriteItinerary(response.data));
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  }
}

export function create_itinerary(newData, tempFile, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  if (tempFile != "" && tempFile != undefined) {
    bodyFormData.append("image", tempFile);
  }
  return async dispatch => {
    const url = baseURL + `itinerary/`;
    return axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(itineraryActionCreators.createItinerary(response.data));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function update_itinerary(newData, itineraryId, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    if (key !== "image") {
      bodyFormData.append(key, newData[key]);
    }
  }
  return async dispatch => {
    const url = baseURL + `itinerary/${itineraryId}/`;
    return axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(itineraryActionCreators.updateItinerary(response.data));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function delete_itinerary(itineraryId, token) {
  return async dispatch => {
    dispatch(itineraryActionCreators.deleteItinerary(itineraryId));
    const url = baseURL + `itinerary/${itineraryId}/`;
    return axios({
      method: "DELETE",
      url: url,
      headers: {
        Authorization: "Token " + token
      }
    }).catch(error => {
      alert(JSON.stringify(error.response.data));
      refresh_token(token);
      console.log(error);
    });
  };
}

/**
 * Trip
 */
export function restore_trip(itineraryId, token) {
  if (token === undefined) {
    return async dispatch => {
      const url = baseURL + `day-trip/?itinerary=${itineraryId}`;
      dispatch(itineraryActionCreators.clearInitTrip());
      return axios({
        method: "GET",
        url: url,
      })
        .then(response => {
          dispatch(itineraryActionCreators.initTrip(response.data));
          dispatch(itineraryActionCreators.initCityList());
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  } else {
    return async dispatch => {
      const url = baseURL + `day-trip/?itinerary=${itineraryId}`;
      dispatch(itineraryActionCreators.clearInitTrip());
      return axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Token " + token
        }
      })
        .then(response => {
          dispatch(itineraryActionCreators.initTrip(response.data));
          dispatch(itineraryActionCreators.initCityList());
        })
        .catch(error => {
          refresh_token(token);
          alert(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  }
}

export function restore_single_trip(tripId, token) {
  return async dispatch => {
    return axios({
      method: "GET",
      url: baseURL + `day-trip-site/?day_trip=${tripId}`,
      headers: {
        Authorization: "Token " + token
      },
    })
      .then(response => {
        dispatch(itineraryActionCreators.initSingleTrip(response.data));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function create_trip(newData, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    const url =
      baseURL + `day-trip/`;
    return axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(itineraryActionCreators.createTrip(response.data));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function create_trip_site(newData, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    const url =
      baseURL + `day-trip-site/`;
    return axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(
          itineraryActionCreators.createTripSite(response.data)
        );
      }).then(
        dispatch(
          restore_single_trip(newData.day_trip, token)
        )
      )
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

// update day
export function update_trip(newData, tripId, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    const url = baseURL + `day-trip/${tripId}/`;
    return axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(itineraryActionCreators.updateTrip(response.data));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

// updating site order
export function update_trip_site(
  newData,
  tripsiteId,
  token
) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    return axios({
      method: "PATCH",
      url: baseURL + `day-trip-site/${tripsiteId}/?new_order=${newData.order}`,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(response => {
        dispatch(
          itineraryActionCreators.updateDayTripSite(response.data)
        );
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function delete_trip(tripId, tripIndex, token) {
  return async dispatch => {
    return axios({
      method: "DELETE",
      url: baseURL + `day-trip/${tripId}/`,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(response => {
        dispatch(itineraryActionCreators.deleteTrip(tripId));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function delete_trip_site(tripSiteId, siteIndex, token) {
  return async dispatch => {
    const url = baseURL + `day-trip-site/${tripSiteId}/`;
    return axios({
      method: "DELETE",
      url: url,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(response => {
        dispatch(itineraryActionCreators.deleteTripSite(siteIndex));
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

/**
 * Featured Itin
 */
export function restore_featured_itin() {
  return async dispatch => {
    const url = baseURL + `featured/`;
    return axios({
      method: "GET",
      url: url
    })
      .then(response => {
        dispatch(itineraryActionCreators.initFeaturedItin(response.data));
      })
      .catch(error => {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}


/**
 * Like
 */

export function like_itinerary(newData, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    return axios({
      method: "POST",
      url: baseURL + `like/`,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(response => {
        dispatch(itineraryActionCreators.likeItinerary());
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}


export function unlike_itinerary(itineraryId, token) {
  return async dispatch => {
    return axios({
      method: "DELETE",
      url: baseURL + `like/${itineraryId}`,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(response => {
        dispatch(itineraryActionCreators.unlikeItinerary());
      })
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}


export function like_itinerary_home(newData, itinIndex, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    return axios({
      method: "POST",
      url: baseURL + `like/`,
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(
        dispatch(adminActionCreators.likeItineraryHome(itinIndex))
      ).then(
        dispatch(itineraryActionCreators.likeTrendingItineraryById(newData.itinerary))
      )
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}


export function unlike_itinerary_home(itineraryId, itinIndex, token) {
  return async dispatch => {
    return axios({
      method: "DELETE",
      url: baseURL + `like/${itineraryId}`,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(
        dispatch(adminActionCreators.unlikeItineraryHome(itinIndex))
      )
      .then(
        dispatch(itineraryActionCreators.unlikeTrendingItineraryById(itineraryId))
      )
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}

export function like_itinerary_trending(newData, itinIndex, token) {
  let bodyFormData = new FormData();
  for (let key in newData) {
    bodyFormData.append(key, newData[key]);
  }
  return async dispatch => {
    return axios({
      method: "POST",
      url: baseURL + "like/",
      headers: {
        Authorization: "Token " + token
      },
      data: bodyFormData
    })
      .then(
        dispatch(itineraryActionCreators.likeTrendingItinerary(itinIndex))
      )
      .then(
        dispatch(adminActionCreators.likeItineraryHomeById(newData.itinerary))
      )
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}


export function unlike_itinerary_trending(itineraryId, itinIndex, token) {
  return async dispatch => {
    return axios({
      method: "DELETE",
      url: baseURL + `like/${itineraryId}`,
      headers: {
        Authorization: "Token " + token
      }
    })
      .then(
        dispatch(itineraryActionCreators.unlikeTrendingItinerary(itinIndex))
      )
      .then(
        dispatch(adminActionCreators.unlikeItineraryHomeById(itineraryId))
      )
      .catch(error => {
        refresh_token(token);
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  };
}