import React, { Component } from 'react'
import { register } from '../../actions/api/user';
import { connect } from 'react-redux';

import RegisterForm from './RegisterForm';

class RegisterPage extends Component {

  submit_register = (username, firstname, lastname, email, password) => {
    this.props.dispatch(register(username, firstname, lastname, email, password));
    this.props.handleMenuClose();
  }

  render() {
    return (
      <div>
        <RegisterForm onSubmit={this.submit_register} logged_in={this.props.logged_in} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    logged_in: state.auth.access_token !== undefined,
  };
}

export default connect(mapStateToProps)(RegisterPage);
