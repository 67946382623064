import { combineReducers } from 'redux';
// import { reducer as userProfileReducer } from '../components/user_profile/store';
import { reducer as adminReducer } from '../components/admin/store';
import appReducer from '../reducers/app';
import authReducer from '../reducers/auth';
import { reducer as userReducer } from '../components/user_profile/store'
import { reducer as itineraryReducer } from '../components/itinerary/store';

const reducer = combineReducers({
  "app": appReducer,
  "admin": adminReducer,
  "auth": authReducer,
  "user": userReducer,
  "itinerary": itineraryReducer,
});

export default reducer;
